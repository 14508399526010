import { GPAY_GATEWAY_MERCHANT_ID } from "config";
// import { GPAY_MERCHANT_ID } from "config";

export const QR_TYPE = {
  DEFAULT: "DEFAULT",
  REQUEST: "REQUEST",
  CHECK: "CHECK",
  CUSTOM: "CUSTOM",
};

export const PAYMENT_SERVICE_STATE = {
  LOADING: "LOADING",
  AVAILABLE: "AVAILABLE",
  NOT_AVAILABLE: "NOT_AVAILABLE",
};

export const AVAILABLE_PAYMENT_TYPES = {
  CARD: "card",
  APPLE_PAY: "apple",
  GOOGLE_PAY: "gpay",
  BINANCE_PAY: "binance",
};

export const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

export const tokenizationSpecification = {
  type: "PAYMENT_GATEWAY",
  parameters: {
    gateway: "georgiancard",
    gatewayMerchantId: GPAY_GATEWAY_MERCHANT_ID,
  },
};

export const tokenizationSpecificationUfc = {
  type: "PAYMENT_GATEWAY",
  parameters: {
    gateway: "unitedfinancialcorporation",
    gatewayMerchantId: "5661001",
  },
};

// export const tokenizationSpecificationUfc2 = () =>{
// return {  type: "PAYMENT_GATEWAY",
//   parameters: {
//     gateway: "unitedfinancialcorporation",
//     gatewayMerchantId: "5661002",
//   }},
// };

export const tokenizationSpecificationUfc3 = (gatewayId) => {
  return {
    type: "PAYMENT_GATEWAY",
    parameters: {
      gateway: "unitedfinancialcorporation",
      gatewayMerchantId: gatewayId,
    },
  };
};

export const tokenizationSpecificationTbc = {
  type: "PAYMENT_GATEWAY",
  parameters: {
    gateway: "unitedfinancialcorporation",
    gatewayMerchantId: "5303890",
  },
};

export const allowedCardNetworks = ["MASTERCARD", "VISA"];

// export const allowedCardNetworksUfc = ["MASTERCARD", "VISA"];

// export const allowedCardNetworksTbc = ["MASTERCARD", "VISA"];

export const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

export const baseCardPaymentMethod = {
  type: "CARD",
  parameters: {
    allowedAuthMethods: allowedCardAuthMethods,
    allowedCardNetworks: allowedCardNetworks,
  },
};

// export const baseCardPaymentMethodUfc = {
//   type: "CARD",
//   parameters: {
//     allowedAuthMethods: allowedCardAuthMethods,
//     allowedCardNetworks: allowedCardNetworksUfc,
//   },
// };
// export const baseCardPaymentMethodTbc = {
//   type: "CARD",
//   parameters: {
//     allowedAuthMethods: allowedCardAuthMethods,
//     allowedCardNetworks: allowedCardNetworksTbc,
//   },
// };

export const cardPaymentMethod = Object.assign(
  { tokenizationSpecification: tokenizationSpecification },
  baseCardPaymentMethod
);

export const cardPaymentMethodUfc = Object.assign(
  { tokenizationSpecification: tokenizationSpecificationUfc },
  baseCardPaymentMethod
);

export const cardPaymentMethodUfc2 = (gatewayId) => {
  return Object.assign(
    { tokenizationSpecification: tokenizationSpecificationUfc3(gatewayId) },
    baseCardPaymentMethod
  );
};

export const cardPaymentMethodTbc = Object.assign(
  { tokenizationSpecification: tokenizationSpecificationTbc },
  baseCardPaymentMethod
);

export const INITIAL_STATE = {
  amount: "",
  loadingPayment: false,
  gpayState: PAYMENT_SERVICE_STATE.LOADING,
  applePayState: PAYMENT_SERVICE_STATE.LOADING,
  inputError: false,
  showPaymentModal: false,
  paymentType: "",
  selectProducts: null,
  showInstallmentModal: false,
  convertedAmounts: [],
  preDiscountAmount: "",
};

export const getAvailablePaymentOptions = ({
  acquiringType,
  gpayState,
  applePayState,
  PAYMENT_SERVICE_STATE,
  AVAILABLE_PAYMENT_TYPES,
}) => [
  ...(acquiringType?.availableAcquiringTypes?.STANDARD
    ? [AVAILABLE_PAYMENT_TYPES.CARD]
    : []),
  ...(gpayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
  acquiringType?.availableAcquiringTypes?.GOOGLE_PAY
    ? [AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY]
    : []),
  ...(applePayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
  acquiringType?.availableAcquiringTypes?.APPLE_PAY
    ? [AVAILABLE_PAYMENT_TYPES.APPLE_PAY]
    : []),
  ...(acquiringType?.availableAcquiringTypes?.CRYPTO
    ? [AVAILABLE_PAYMENT_TYPES.BINANCE_PAY]
    : []),
  ...(acquiringType?.availableAcquiringTypes?.OPEN_BANKING ? ["ob"] : []),
  ...(acquiringType?.availableAcquiringTypes?.INSTALLMENT
    ? ["installment"]
    : []),
];
