import useSWR from "swr";
import { MERCHANT_PROFILE_URL } from "helpers/fetcher/fetcher.config";
import { decrypt, decryptAES, parseBase64 } from "utils/encryption";
import { paymentServiceInstance } from "api";

const useMerchantData = (receiverType, receiverUserId, qrType) => {
  const {
    data: merchant,
    isLoading: isLoadingMerchnt,
    error: { response: { data: merchantError } = {} } = {},
  } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&qrType=${qrType}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretKey = parseBase64(base64SecretKey);
      const iv = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretKey, iv);
      return encrypted;
    }
  );

  return {
    merchant,
    isLoadingMerchnt,
    merchantError,
  };
};

export default useMerchantData;
