import { useMemo } from "react";
import useSWR from "swr";
import JSEncrypt from "jsencrypt";
import { eCommerceServiceInstance } from "api";
import { SET_AMOUNT } from "features/bog/reducer/actions";
import { LAST_ACTIVE_ORDER } from "helpers/fetcher/fetcher.config";
import { QR_TYPE } from "features/bog/config";

const useLastActiveOrder = (
  integratorId,
  qrType,
  receiverUserId,
  dispatch,
  setFormData,
  setIsNonResident,
  integratorCheckOrderId
) => {
  const fetcher = async (url) => {
    const {
      data: {
        value: { encryptedData },
      },
    } = await eCommerceServiceInstance.get(url);

    const encrypt = new JSEncrypt();
    encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
    const decryptedData = JSON.parse(encrypt.decrypt(encryptedData));

    const {
      amount: orderedAmount,
      prefilledValues: {
        purpose = "",
        personalNumber = "",
        personalNumberOrPassport = "",
        payerName = "",
        isForeign = false,
      },
    } = decryptedData;

    dispatch({ type: SET_AMOUNT, payload: orderedAmount });
    setFormData({
      payerName: payerName,
      payerPersonalNumber: personalNumber || personalNumberOrPassport,
      additionalDescription: purpose,
    });
    setIsNonResident(isForeign);

    return decryptedData;
  };

  const {
    data: {
      integratorOrderId = integratorCheckOrderId,
      currency: currencyCheck = "",
      amount: checkAmount,
      prefilledValues = {},
      acquiringCurrency: acquiringCurrencyCheck,
    } = {},
    error: qrCheckError,
  } = useSWR(
    useMemo(() => {
      if (integratorId.length && qrType !== QR_TYPE.REQUEST) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
        const encryptedData = encodeURIComponent(
          encrypt.encrypt(
            JSON.stringify({
              integratorId: integratorId,
              receiverId: receiverUserId,
            })
          )
        );

        return `${LAST_ACTIVE_ORDER}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${encryptedData}`;
      }
      return null;
    }, [integratorId, qrType, receiverUserId]),
    fetcher
  );

  return {
    integratorOrderId,
    currencyCheck,
    prefilledValues,
    acquiringCurrencyCheck,
    checkAmount,
    qrCheckError,
  };
};

export default useLastActiveOrder;
