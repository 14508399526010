import {
  cityPayStatusCheck,
  obStatusCheck,
  obStatusCheckRepeat,
  postTransactionAction,
} from "api";
import { useQueryString } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NeolletPending = () => {
  const { parseQuery: parsedQuery } = useQueryString();
  const {
    acquiringTransactionId,
    code = "",
    state = "",
    error = false,
    paymentProviderEnum,
    ratingEnabled,
    amount = "",
    receiverName = "",
    receiverId = "",
    receiverType = "",
    tipsEnabled,
    token = "",
    currency = "",
    integratorOrderId,
    // genericId: genericid,
    showAwardedGiftComponent = "false",
    acquiringType,
  } = parsedQuery;
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      const fetchData = async () => {
        try {
          const {
            status,
            amount: amountNeolett,
            currency: currencyNeolett,
            ratingEnabled: ratingEnabledNeolett,
            tipsEnabled: tipsEnabledNeolett,
            receiverName: receiverNameNeolett,
            receiverId: receiverIdNeolett,
            beneficiaryType: receiverTypeNeolett,
            acquiringTransactionId: acquiringTransactionIdNeolett,
            integratorOrderId,
            genericId,
            showAwardedGiftComponent = "false",
          } = await obStatusCheck({
            state: state,
            code: code || null,
          });

          if (error) {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionIdNeolett}&receiverId=${receiverIdNeolett}&receiverType=${receiverTypeNeolett}`
            );
            return;
          }

          const { redirectRequired, successRedirectUri, failRedirectUri } =
            await postTransactionAction(integratorOrderId);

          const url = `/success?acquiringTransactionId=${acquiringTransactionIdNeolett}&amount=${amountNeolett}&receiverType=${receiverTypeNeolett}&receiverName=${receiverNameNeolett}&ratingEnabled=${ratingEnabledNeolett}&tipsEnabled=${tipsEnabledNeolett}&receiverId=${receiverIdNeolett}&paymentProviderEnum=OB&paymentMethod=OB&currency=${currencyNeolett}&integratorOrderId=${integratorOrderId}&genericId=${genericId}&showAwardedGiftComponent=${showAwardedGiftComponent}`;

          if (redirectRequired && status != "PENDING") {
            if (
              status == "SUCCESSFUL" &&
              !tipsEnabledNeolett &&
              !ratingEnabledNeolett
            ) {
              window.location.href = successRedirectUri;
              return;
            } else if (
              status == "SUCCESSFUL" &&
              (tipsEnabledNeolett || ratingEnabledNeolett)
            ) {
              navigate(url);
              return;
            } else if (status == "FAILED") {
              window.location.href = failRedirectUri;
              return;
            }
          } else if (status == "SUCCESSFUL") {
            navigate(url);
          } else if (status == "FAILED") {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionIdNeolett}&receiverId=${receiverIdNeolett}&receiverType=${receiverTypeNeolett}`
            );
          } else if (status == "PENDING") {
            setTimeout(async () => {
              const { transactionStatus } = await obStatusCheckRepeat(
                acquiringTransactionIdNeolett
              );

              if (redirectRequired && transactionStatus != "PENDING") {
                if (
                  transactionStatus == "SUCCESSFUL" &&
                  !tipsEnabledNeolett &&
                  !ratingEnabledNeolett
                ) {
                  window.location.href = successRedirectUri;
                  return;
                } else if (
                  transactionStatus == "SUCCESSFUL" &&
                  (tipsEnabledNeolett || ratingEnabledNeolett)
                ) {
                  navigate(url);
                  return;
                } else if (transactionStatus == "FAILED") {
                  window.location.href = failRedirectUri;
                  return;
                }
              } else if (transactionStatus == "SUCCESSFUL") {
                navigate(url);
              } else if (transactionStatus == "FAILED") {
                navigate(
                  `/payment-failed?acquiringTransactionId=${acquiringTransactionIdNeolett}&receiverId=${receiverIdNeolett}&receiverType=${receiverTypeNeolett}`
                );
              } else if (transactionStatus == "PENDING") {
                setTimeout(async () => {
                  const { transactionStatus } = await obStatusCheckRepeat(
                    acquiringTransactionId
                  );

                  if (redirectRequired && transactionStatus != "PENDING") {
                    if (
                      transactionStatus == "SUCCESSFUL" &&
                      !tipsEnabledNeolett &&
                      !ratingEnabledNeolett
                    ) {
                      window.location.href = successRedirectUri;
                      return;
                    } else if (
                      transactionStatus == "SUCCESSFUL" &&
                      (tipsEnabledNeolett || ratingEnabledNeolett)
                    ) {
                      navigate(url);
                      return;
                    } else if (transactionStatus == "FAILED") {
                      window.location.href = failRedirectUri;
                      return;
                    }
                  } else if (transactionStatus == "SUCCESSFUL") {
                    navigate(url);
                  } else if (transactionStatus == "FAILED") {
                    navigate(
                      `/payment-failed?acquiringTransactionId=${acquiringTransactionIdNeolett}&receiverId=${receiverIdNeolett}&receiverType=${receiverTypeNeolett}`
                    );
                  }
                }, 5000);
              }
            }, 5000); // 5000 milliseconds = 5 seconds
          } else {
            console.log(" ");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();

      return;
    }

    if (paymentProviderEnum == "CITYPAY") {
      cityPayStatusCheck(acquiringTransactionId).then(
        ({ data: { status } }) => {
          if (status == "confirmed") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&paymentMethod=citypay&paymentProviderEnum=${paymentProviderEnum}&receiverId=${receiverId}&receiverType=${receiverType}&integratorOrderId=${integratorOrderId}&showAwardedGiftComponent=${showAwardedGiftComponent}&acquiringType=${acquiringType}&currency=${currency}`;
            navigate(successUrl);
            return;
          } else {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}&integratorOrderId=${integratorOrderId}`
            );
            return;
          }
        }
      );

      return;
    }
  }, []);
  return (
    <div className="flex flex-col justify-center bg-[#25292B] items-center h-full w-full">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="18" cy="18" rx="15" ry="15" fill="#FF9D00" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.7026 21.5606C19.7904 21.5606 21.4829 19.8681 21.4829 17.7803C21.4829 15.6925 19.7904 14 17.7026 14C15.9009 14 14.3936 15.2604 14.0143 16.9477L14.3359 16.6261C14.7093 16.2527 15.3146 16.2527 15.688 16.6261C16.0613 16.9995 16.0613 17.6048 15.688 17.9782L13.6815 19.9846C13.6746 19.992 13.6675 19.9994 13.6602 20.0066C13.2868 20.38 12.6815 20.38 12.3081 20.0066L12.3078 20.0063L10.28 17.9785C9.90666 17.6051 9.90666 16.9998 10.28 16.6264C10.6534 16.2531 11.2587 16.2531 11.6321 16.6264L11.9783 16.9726C12.3712 14.1626 14.7843 12 17.7026 12C20.895 12 23.4829 14.5879 23.4829 17.7803C23.4829 20.9727 20.895 23.5606 17.7026 23.5606C17.1503 23.5606 16.7026 23.1129 16.7026 22.5606C16.7026 22.0083 17.1503 21.5606 17.7026 21.5606Z"
          fill="white"
        />
      </svg>

      <p className="text-[14px] font-bold text-[#FFFFFF] mt-[24px] ">
        ტრანზაქცია მუშავდება
      </p>
      <p className="text-[12px] text-[#BBBBBB] mt-[12px]">
        ტრანზაქცია დამუშავების პროცესშია. <br /> მადლობას გიხდით მოთმინებისთვის
      </p>
    </div>
  );
};

export default NeolletPending;
