import { resetFailOrder } from "api";
import {
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
} from "features/bog/reducer/actions";

export const getSymbol = (curr) => {
  return {
    USD: "$",
    EUR: "€",
    GEL: "₾",
  }[curr];
};

export const onCancelGoogleWallet = (
  err,
  qrType,
  integratorId,
  integratorOrderId,
  dispatch
) => {
  if (
    err.toString().includes("AbortError") &&
    qrType != "REQUEST" &&
    integratorOrderId
  ) {
    resetFailOrder({
      integratorId: integratorId,
      integratorOrderId: integratorOrderId,
    })
      .then((data) => {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: 100,
            group: 1,
          },
        });
      })
      .catch((err) => console.log(err, "error occured while resetting"));
  }

  dispatch({ type: SET_LOADING_PAYMENT, payload: false });
};

export const ceilToTwo = (num) => {
  let pair = `${num}e`.split("e");
  const value = Math.ceil(Number(pair[0] + "e" + (+pair[1] + 2)));
  pair = `${value}e`.split("e");
  return Number(pair[0] + "e" + (+pair[1] - 2));
};

export const paintDiscountBadge = ({
  labelText = "-50%",
  labelColor = "red",
  labelTextColor = "white",
  substraction = null,
  capPerTransaction = null,
  amountInserted = false,
  currency = null,
  isPercentage = false,
}) => {
  console.log({
    labelText,
    labelColor,
    labelTextColor,
    substraction,
    capPerTransaction,
    amountInserted,
    currency,
    isPercentage,
  });

  const currencySymbol = getSymbol(currency);
  let label;
  if (amountInserted) {
    label = `- ${labelText} ${isPercentage ? "%" : currencySymbol}`;
  } else {
    label = labelText;
  }
  return (
    <div
      style={{
        backgroundColor: labelColor,
        borderRadius: "5px",
        // width: "40px",
        paddingLeft: "5px",
        paddingRight: "5px",
        height: "20px",
      }}
    >
      <p
        style={{
          color: labelTextColor,
          fontSize: "12px",
          textAlign: "center",
          margin: "auto",
        }}
      >
        {substraction && substraction > capPerTransaction
          ? `-${capPerTransaction} ${currencySymbol}`
          : label}
      </p>
    </div>
  );
};

export const sortUserLabelsByLabelId = (user = [], labelType = "") => {
  return user?.labels?.[labelType]?.sort((a, b) => b.labelId - a.labelId);
};

export const getCampaignName = (paymentType) => {
  return {
    apple: "APPLE_PAY",
    gpay: "GOOGLE_PAY",
    ob_bog: "OB",
    ob_tbc: "OB",
    ob_credo: "OB",
    ob_liberty: "OB",
    ob_basis: "OB",
    CITY_PAY_WALLET: "CRYPTO",
    CITY_PAY_BINANCE: "CRYPTO",
    CITY_PAY: "CRYPTO",
    installment: "INSTALLMENT",
    card: "STANDARD",
  }[paymentType];
};
