import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

export const SkiPassPage = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("ge");
  }, []);

  return (
    <div className="sm:px-[20px] md:px-0">
      <h1
        className="font-bold text-[34px] mt-[80px] "
        style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
      >
        <Trans i18nKey={"campaign.winSkipass"} />
      </h1>
      <div className="mt-[20px]">
        <p style={{ color: "#777987", fontSize: "16px" }}>
          <Trans i18nKey={"campaign.rules"} />
        </p>
        <ul
          style={{
            listStyleType: "disc",
            listStylePosition: "inside",
            marginTop: "15px",
          }}
        >
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.firstBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.secondBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.thirdBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.fourthBullet"} />
          </li>
        </ul>
        <p className="mt-[8px]" style={{ color: "#777987", fontSize: "16px" }}>
          {" "}
          <Trans i18nKey={"campaign.wishULuck"} />
        </p>
        <p className="mt-[8px]" style={{ color: "#777987", fontSize: "16px" }}>
          <Trans i18nKey={"campaign.fir"} />
        </p>
      </div>
      <h1
        className="font-bold text-[34px] mt-[80px] "
        style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
      >
        <Trans i18nKey={"campaign.storesToWin"} />
      </h1>
      <div className="mt-[40px]">
        <h2
          className="text-[27px] text-[#6C63FF] font-bold"
          style={{ fontVariant: "all-petite-caps" }}
        >
          <Trans i18nKey={"campaign.gudauri"} />
        </h2>
        <div className=" md:gap-[10px] flex flex-wrap">
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop1"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop1Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598928055"
            >
              598928055
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop2"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop2Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599902046"
            >
              599902046
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop3"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop3Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598909082"
            >
              598909082
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop4"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop4Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+592712471"
            >
              592712471
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop5"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop5Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+511286018"
            >
              511286018
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop6"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop6Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+577327058"
            >
              577327058
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop7"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop7Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591288803"
            >
              591288803
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop8"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop8Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+571054051"
            >
              571054051
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop9"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop9Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598582006"
            >
              598582006
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop10"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop10Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+568707777"
            >
              568707777
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop11"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop11Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557737109"
            >
              557737109
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop12"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop12Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598149144"
            >
              598149144
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop13"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop13Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551872287"
            >
              551872287
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop14"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop14Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+579909834"
            >
              579909834
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop15"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop15Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+593444388"
            >
              593444388
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop16"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop16Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557671228"
            >
              557671228
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop17"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop17Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+574251759"
            >
              574251759
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop18"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop18Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599463326"
            >
              599463326
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop19"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop19Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+514500700"
            >
              514500700
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop20"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop20Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597785741"
            >
              597785741
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop21"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop21Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597785741"
            >
              591907876
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop22"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop22Location"} />
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop23"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop23Location"} />
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.vasilZakaidze"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551700066"
            >
              551700066
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.irinaAbashidze"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599939005"
            >
              599939005
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">Ski Line Gudauri</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop3Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599989962"
            >
              599989962
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.buranRental"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.gigaSeturidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557002524"
            >
              557002524
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.davidZakaidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599997655"
            >
              599997655
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.irakliWamaldze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599843288"
            >
              599843288
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop15"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.gochaBedoidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599450421"
            >
              599450421
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.gigaKavtaradze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551025256"
            >
              551025256
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.koba"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551170580"
            >
              551170580
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.buggyRental"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.gela"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+595564401"
            >
              595564401
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.shop5"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.lukaJ"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599025517"
            >
              599025517
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.nugzarBedoidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557410300"
            >
              557410300
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.shop13"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.valerian"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591979529"
            >
              591979529
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.paraGlidInst"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.ketevanN"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551312331"
            >
              551312331
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.shop5"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.sandroJaniashvili"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+511171880"
            >
              511171880
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">TAXI</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.nodarTsiklauri"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+558539538"
            >
              558539538
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.passengerTrans"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.davidJaniashvili"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551134764"
            >
              551134764
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.passengerTransWithDelic"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.dimitriSeturidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+571118118"
            >
              571118118
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop5"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.davBedoidze"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551801808"
            >
              551801808
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              {" "}
              <Trans i18nKey={"campaign.shop2"} />
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              <Trans i18nKey={"campaign.maia"} />
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597908360"
            >
              597908360
            </a>
          </div>
        </div>
        <h2
          className="text-[27px] mt-[20px] text-[#6C63FF] font-bold"
          style={{ fontVariant: "all-petite-caps" }}
        >
          <Trans i18nKey={"campaign.bakuriani"} />
        </h2>
        <div className="md:gap-[10px] flex flex-wrap">
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თხილამურების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              დავით ჩუბკო, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599540429"
            >
              599540429
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თხილამურების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              მერი კოზანაშვილი, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599512945"
            >
              599512945
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ზავენ გაბრიელიან, 25-ების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551930095"
            >
              551930095
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              თხილამურების გაქირავება/ინსტრუქტორი
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              გიორგი კოზანაშვილი, 25-ების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599548827"
            >
              599548827
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ნიკოლოზ ჩოჩნიძე, 25-ების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591808056"
            >
              591808056
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              გიორგი ბეშკენაძე, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598915845"
            >
              598915845
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              რევაზი მეყანწიშვილი, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+595021415"
            >
              595021415
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ანზორ თედელური, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+568088006"
            >
              568088006
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              მამუკა კობახიძე, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+592969771"
            >
              592969771
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ნიკა აჩელიშვილი, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551160627"
            >
              551160627
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              უშანგი გელაშვილი, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557223666"
            >
              557223666
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">კაფე,რესტორანი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ლიანა გიორგაძე, თრიალეთის ბ ბლოკი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591300555"
            >
              591300555
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">შერეული საქონლის მაღაზია</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ვასილი ფერაძე, აღმაშენებლის ქ.120
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+596745454"
            >
              596745454
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თხილამურის ინსტრუქტორი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              გია გვარამაძე, საბაგირო "კრისტალის" მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+555621376"
            >
              555621376
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თხილამურის ინსტრუქტორი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ქრისტეფორე გელცმანი, საბაგირო "კრისტალის" მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+577555560"
            >
              577555560
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თხილამურების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ანზორ ქუმარიტაშვილი, აღმაშენებლის ქ.122
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598777758"
            >
              598777758
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">
              ციგები/კვადროციკლების გაქირავება
            </p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ბესიკ სიდამონიძე, დიდველის ქუჩა
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+555222737"
            >
              555222737
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ციგები/სათამაშო ატრაქციონები</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              თეონა სამხარაძე, თრიალეთის ქუჩა
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598858628"
            >
              598858628
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ინსტრუქტორი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              ალიკა გასიევი, აღმაშენებლის ქ.114
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+593788779"
            >
              593788779
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ტაქსი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ხვიჩა მეგრელიშვილი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557467303"
            >
              557467303
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ტაქსი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              რამაზი აბესაძე
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+593334404"
            >
              593334404
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">შერეული საქონლის მაღაზია</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ედუარდ გეორქოვი, აღმაშენებლის 19ა
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+577188796"
            >
              577188796
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით ქიტიაშვილი, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597754839"
            >
              597754839
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">კაფე 25</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ირინე შამოიანი, წაქაძის 134ბ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557691302"
            >
              557691302
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">გოჩიტს ბურგერი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              მანანა ხურციძე, წაქაძის ქ.138
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599885054"
            >
              599885054
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">თონე</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              მიხეილ ემინიდის, აღმაშენებლის ქ.66
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598336540"
            >
              598336540
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">რესტორანი თბილისი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              გიორგი კაპანაძე, აღმაშენებლის ქ.19
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+558723377"
            >
              558723377
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">სათამაშო ატრაქციონები</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ციალა მურჯიკნელი, საბაგირო "კრისტალის" მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599170369"
            >
              599170369
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">სათამაშო ატრაქციონები/ციგურები</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ლაშა კიკვაძე, კოხტა-მიტარბი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597780778"
            >
              597780778
            </a>
          </div>
          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              სოსო მელიქიძე, ბაკურიანი რეზიდენსი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551630630"
            >
              551630630
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              მარიამ ბლიაძე, პარკის მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591339761"
            >
              591339761
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ბურანების გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ლაშა სექანია, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+579208017"
            >
              579208017
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ივენთების ორგანიზატორი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ელენე ბეჟიაშვილი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598913223"
            >
              598913223
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">ტირი, ციგების საბაგირო</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              გიორგი ხუბაევი, პარკის მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+593000446"
            >
              593000446
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">შერეული საქონლით ვაჭრობა</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ნინო ტაბატაძე, აღმაშენებლის 3
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599558570"
            >
              599558570
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">შერეული საქონლით ვაჭრობა</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ნაირა ყურმაშვილი, აღმაშენებლის ქ. 70
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+571221240"
            >
              571221240
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">საბაგირო</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ზურაბი კურტანიძე, 25-იანების მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+574433344"
            >
              574433344
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">კვების ობიექტი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ირაკლი თედიაშვილი, ცენტრალური პარკი ჯიხური 6
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599628181"
            >
              599628181
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">შერეული საქონლით ვაჭრობა</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              თინა ხორბალაძე, აღმაშენებლის 58
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599566145"
            >
              599566145
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">სასტუმრო რედკო - ველი</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ლევან ვეზირიშვილი, დიდველის მიმდებარედ
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599506730"
            >
              599506730
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">რესტორანი დიდველი გალერეა</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              მიხეილ ხარშილაძე, დავით აღმაშენებლის 38
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+568223723"
            >
              568223723
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">კაფე</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ანა კორძაძე, ცენტრი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+555100105"
            >
              555100105
            </a>
          </div>

          <div class="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p class="text-[16px] font-bold">კოტეჯის გაქირავება</p>
            <p class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              კახა აჩელიშვილი
            </p>
            <span class="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>
            <a
              class="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591404244"
            >
              591404244
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkiPassPage;
