import { acceptApplePay, postTransactionAction, resetFailOrder } from "api";
import axios from "axios";
import {
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
} from "features/bog/reducer/actions";
import { JSEncrypt } from "jsencrypt";

const BASE_URL =
  "https://mpi.gc.ge/open/api/v4/66EF9A2E6D429D8F0C767574F9353E8B";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);

export function validateMerchant(token, merchantValidationUrl) {
  const options = {
    method: "POST",
    url: `${BASE_URL}/payment/${token}/applepay/validate`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: new URLSearchParams({
      merchantValidationUrl: merchantValidationUrl,
    }),
  };
  return axios.request(options);
}

const startApplePaySession = (
  {
    transactionIdPromise,
    amount,
    receiverType,
    receiverName,
    ratingEnabled,
    tipsEnabled,
    receiverId,
    navigate,
    integratorOrderId,
    integratorId,
    dispatch,
    qrType,
    isTip = false,
    setTipError = null,
    acquiringCurrency,
  },
  callback = null
) => {
  const request = {
    countryCode: "GE",
    currencyCode: acquiringCurrency,
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    merchantCapabilities: ["supports3DS"],
    total: { label: "Keepz", amount: amount.toString() },
  };
  let genericId = "";
  let applePayToken = "";
  let merchantTransactionId = "";
  let startPaymentError = undefined;
  const session = new window.ApplePaySession(10, request);
  session.onvalidatemerchant = async function (event) {
    const { validationURL } = event;
    transactionIdPromise
      .then((data) => {
        applePayToken = data.token;
        merchantTransactionId = data.params.transaction_id;
        genericId = data.genericId;
        if (data?.state === "offer") {
          validateMerchant(applePayToken, validationURL)
            .then(({ data }) => {
              session.completeMerchantValidation(data);
            })
            .catch((err) => {
              session.abort();
              if (!isTip) {
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: "",
                    group: 4,
                  },
                });
              } else if (isTip) {
                setTipError({
                  show: true,
                  type: "",
                  group: 4,
                });
              }
            });
        }
      })

      .catch((err) => {
        startPaymentError = err;
        session.abort();
        const result = {
          status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
        };
        if (callback) {
          callback(result);
        }
      });
  };

  session.onpaymentmethodselected = (event) => {
    try {
      const update = {
        newTotal: { label: "Keepz", amount },
      };

      session.completePaymentMethodSelection(update);
    } catch (error) {}
  };

  session.onshippingmethodselected = (event) => {
    try {
      const update = {};
      session.completeShippingMethodSelection(update);
    } catch (error) {}
  };

  session.onpaymentauthorized = async (event) => {
    const { payment: payload } = event;

    const acceptPayment = acceptApplePay(payload, {
      token: applePayToken,
      merchantTransactionId,
    });
    let status = {
      status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    };

    acceptPayment
      .then((data) => {
        if (data?.state == "in_progress") {
          navigate(
            `/pending?token=${applePayToken}&acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${amount}&receiverName=${encodeURIComponent(
              receiverName
            )}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&receiverId=${receiverId}`
          );
          return;
        }
        if (data?.result?.status === "SUCCESS") {
          status = window.ApplePaySession.STATUS_SUCCESS;
        }
        const result = {
          status,
          transactionId: data.params.transaction_id,
        };
        session.completePayment(result);

        if (callback) {
          callback(result, genericId);
        }
      })
      .catch(({ response: { data: err } }) => {
        session.abort();
        async function failRedirct() {
          if (integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
        }
        failRedirct();
        if (!isTip) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
        } else if (isTip) {
          setTipError({
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          });
        }
      });
  };

  session.oncancel = function (event) {
    if (integratorOrderId && qrType != "REQUEST") {
      resetFailOrder({
        integratorId: integratorId,
        integratorOrderId: integratorOrderId,
      })
        .then((data) => {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 100,
              group: 1,
            },
          });
        })

        .catch((err) => console.log(err));
    }
    // const result = {
    //   status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    // };
    // if (callback) {
    //   callback(result, genericId);
    // }
    dispatch({ type: SET_LOADING_PAYMENT, payload: false });
  };
  session.begin();
};

export default startApplePaySession;

// const BASE_URL_UFC =
//   "https://mpi.gc.ge/open/api/v4/66EF9A2E6D429D8F0C767574F9353E8B";

// export function validateMerchantUfc( merchantValidationUrl) {
//   const options = {
//     method: "POST",
//     url: `${BASE_URL}/payment/${token}/applepay/validate`,a
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded" 'application/json',
//     },
//     data:  {
//           type: "applepay_session",
//           attributes: {
//             url: merchantValidationUrl
//           }
//         },
//   };
//   return axios.request(options);
// }

// const startApplePaySessionUfc = (
//   { transactionIdPromise, amount },
//   callback = null
// ) => {
//   const request = {
//     countryCode: "GE",
//     currencyCode: "GEL",
//     supportedNetworks: ["visa", "masterCard", "amex", "discover"],
//     merchantCapabilities: ["supports3DS"],
//     total: { label: "Keepz", amount: amount.toString() },
//   };
//   let applePayToken = "";
//   let merchantTransactionId = "";
//   let startPaymentError = undefined;
//   const session = new window.ApplePaySession(2, request);
//   session.onvalidatemerchant = async function (event) {
//     const { validationURL } = event;
//     transactionIdPromise
//       .then((data) => {
//         merchantTransactionId = data.params.transaction_id;
//           validateMerchant(validationURL)
//             .then((response) => {
//               session.completeMerchantValidation(JSON.parse(response.data.attributes.session));
//             })
//             .catch((err) => {
//               console.log(err);
//             });

//       })

//       .catch((err) => {
//         startPaymentError = err;
//         console.log(err, "startApplePaySession");
//       });
//   };

//   session.onpaymentmethodselected = (event) => {
//     try {
//       const update = {
//         newTotal: { label: "Keepz", amount },
//       };

//       session.completePaymentMethodSelection(update);
//     } catch (error) {
//       console.log(error, "onpaymentMethodSelected");
//     }
//   };

//   session.onshippingmethodselected = (event) => {
//     try {
//       console.log("onShippingmethodSelected");
//       const update = {};
//       session.completeShippingMethodSelection(update);
//     } catch (error) {
//       console.log(error, "onShippingmethodSelected");
//     }
//   };

//   session.onpaymentauthorized = async (event) => {
//     const { payment } = event;
//           const payload = {
//         data: {
//           type: "payment",
//           attributes: {
//             apple_pay: {
//               token: payment.token,
//               shipping_contact: payment.shippingContact,
//               application_data: merchantTransactionId,
//               payment_request: request
//             }
//           }
//         }
//       }

//     const acceptPayment = acceptApplePay(payload);
//     let status = {
//       status:
//         startPaymentError?.response?.data.status ||
//         window.ApplePaySession.STATUS_FAILURE,
//     };

//     acceptPayment.then((data) => {
//       if (data?.result?.status === "SUCCESS") {
//         status = window.ApplePaySession.STATUS_SUCCESS;
//       }
//       const result = {
//         status,
//         transactionId: data.params.transaction_id,
//       };
//       session.completePayment(result);

//       if (callback) {
//         callback(result);
//       }
//     });
//   };

//   session.oncancel = function (event) {
//     const result = {
//       status:
//         startPaymentError?.response?.data.status ||
//         window.ApplePaySession.STATUS_FAILURE,
//     };
//     if (callback) {
//       callback(result);
//     }
//   };
//   session.begin();
// };

// export default startApplePaySessionUfc;
