import { paymentServiceInstance } from "api";
import { SET_PAYMENT_RESULT } from "features/bog/reducer/actions";
import { PRODUCT_URL } from "helpers/fetcher/fetcher.config";
import useSWR from "swr";
import { decrypt } from "utils/encryption";

const useProductData = (productId, receiverUserId, receiverType, dispatch) => {
  const fetcher = async (url) => {
    try {
      const {
        data: {
          value: { encryptedData },
        },
      } = await paymentServiceInstance.get(url);
      return decrypt(encryptedData);
    } catch ({ response: { data: err } }) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: err?.statusCode,
          group: err?.exceptionGroup,
        },
      });
    }
  };

  const { data = {} } = useSWR(
    !!productId
      ? `${PRODUCT_URL}?ownerId=${receiverUserId}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}&productId=${productId}&ownerType=${receiverType}`
      : null,
    fetcher
  );

  const {
    amountList: requestedAmount1 = [],
    currency: currencyProduct = "",
    currencyRate: currRateProduct,
    acquiringCurrency: acquiringCurrencyProd,
  } = data;

  return {
    requestedAmount1,
    currencyProduct,
    currRateProduct,
    acquiringCurrencyProd,
  };
};

export default useProductData;
