import ApplePayIcon from "assets/icons/ApplePayIcon";
import CardIcon from "assets/icons/CardIcon";
import { useTranslation } from "react-i18next";
import GooglePayIcon from "assets/icons/GooglePayIcon";
import React, { useState } from "react";
import BitCoinIcon from "assets/icons/BitCoinIcon";
import CryptosIcon from "assets/icons/CryptosIcon";
import OnlineBankIcon from "assets/icons/OnlineBankIcon";
import {
  SET_PAYMENT_TYPE,
  SET_TOGGLE_INSTALLMENT_MODAL,
} from "features/bog/reducer/actions";
import OBPaymentMethod from "components/OBPayment/OBPaymentMethod";
import { CryptoPaymentMethod } from "components/CryptoPayment/CryptoPaymentMethod";
import { paintDiscountBadge, sortUserLabelsByLabelId } from "utils/functions";

function SelectPaymentMethod({
  handleClose,
  changePaymentType,
  availablePaymentOptions = [],
  paymentType,
  dispatch,
  obProviders,
  setObProviderId,
  cryptoList,
  user,

  // setCryptoAcquringType,
}) {
  const { t } = useTranslation();
  const [isObOpen, setIsObOpen] = useState(false);
  const [isCryptoOpen, setIsCryptoOpen] = useState(false);

  // const discountCryptoCampaign = user?.labels?.CRYPTO?.find(
  //   (item) => item.promotionType === "DISCOUNT"
  // );
  const cryptoCampaign = sortUserLabelsByLabelId(user, "CRYPTO") || [];
  const appleCampaign = sortUserLabelsByLabelId(user, "APPLE_PAY") || [];
  const gpayCampaign = sortUserLabelsByLabelId(user, "GOOGLE_PAY") || [];
  const stadndardCampaign = sortUserLabelsByLabelId(user, "STANDARD") || [];
  const obCampaign = sortUserLabelsByLabelId(user, "OB") || [];
  const installmetCampaign = sortUserLabelsByLabelId(user, "INSTALLMET") || [];

  let content;

  if (isObOpen) {
    content = (
      <>
        {obProviders?.map((el, index) => (
          <OBPaymentMethod
            key={index}
            name={el.name}
            id={el.id}
            dispatch={dispatch}
            setObProviderId={setObProviderId}
          />
        ))}
      </>
    );
  } else if (isCryptoOpen) {
    const sortedList = cryptoList.sort((a, b) => a.priority - b.priority);
    content = (
      <>
        {sortedList?.map((el, index) => (
          <CryptoPaymentMethod
            key={index}
            name={el.acquiringName}
            logo={el.logo}
            dispatch={dispatch}
            acquiringType={el.acquiringType}
            // setCryptoAcquringType={setCryptoAcquringType}
          />
        ))}
        <p className="text-sm text-black-400 text-center ">
          Powered by Citypay
        </p>
      </>
    );
  } else {
    content = (
      <>
        {" "}
        {availablePaymentOptions.includes("apple") && (
          <div
            className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-[16px]"
            style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
          >
            <button
              className="h-[30px]"
              onClick={() => {
                changePaymentType("apple");
              }}
            >
              <ApplePayIcon />
              <p>Apple Pay</p>
            </button>
            {user?.labels?.APPLE_PAY && (
              <>
                <div className="flex flex-col justify-start items-end">
                  {paintDiscountBadge({
                    labelText: appleCampaign[0]?.labelText,
                    labelColor: appleCampaign[0]?.labelColor,
                    labelTextColor: appleCampaign[0]?.labelTextColor,
                  })}
                  <div
                    style={{
                      color: appleCampaign[0]?.labelSubTextColor,
                      minWidth: "66px",
                    }}
                    className="text-[12px] text-right"
                  >
                    {appleCampaign[0]?.labelSubText}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {availablePaymentOptions.includes("gpay") && (
          <div
            className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-[16px]"
            style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
          >
            <button
              className="h-[30px]"
              onClick={() => {
                changePaymentType("gpay");
              }}
            >
              <GooglePayIcon />
              <p>Google Pay</p>
            </button>
            {user?.labels?.GOOGLE_PAY && (
              <>
                <div className="flex flex-col justify-start items-end">
                  {paintDiscountBadge({
                    labelText: gpayCampaign[0]?.labelText,
                    labelColor: gpayCampaign[0]?.labelColor,
                    labelTextColor: gpayCampaign[0]?.labelTextColor,
                  })}
                  <div
                    style={{
                      color: gpayCampaign[0]?.labelSubTextColor,
                      minWidth: "66px",
                    }}
                    className="text-[12px] text-right"
                  >
                    {gpayCampaign[0]?.labelSubText}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {availablePaymentOptions.includes("card") && (
          <div
            className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-[16px]"
            style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
          >
            <button
              className="h-[30px]"
              onClick={() => {
                changePaymentType("card");
              }}
            >
              <CardIcon />
              <p>{t("description.payzePayWithCard")}</p>
            </button>
            {user?.labels?.STANDARD && (
              <>
                <div className="flex flex-col justify-start items-end">
                  {paintDiscountBadge({
                    labelText: stadndardCampaign[0]?.labelText,
                    labelColor: stadndardCampaign[0]?.labelColor,
                    labelTextColor: stadndardCampaign[0]?.labelTextColor,
                  })}
                  <div
                    style={{
                      color: stadndardCampaign[0]?.labelSubTextColor,
                      minWidth: "66px",
                    }}
                    className="text-[12px] text-right"
                  >
                    {stadndardCampaign[0]?.labelSubText}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {availablePaymentOptions.includes("binance") && (
          <div
            className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-[16px]"
            style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
          >
            <button
              className="h-[30px]"
              onClick={() => {
                setIsCryptoOpen(true);
                // changePaymentType("binance");
              }}
            >
              <div className="flex justify-between items-center w-[100%]">
                <div className="flex  items-center">
                  <BitCoinIcon width={"45px"} height={"40px"} />
                  <p className="text-left ">
                    <span
                      className="text-[#000000] text-[16px]"
                      style={{
                        fontFamily: "sf-pro-rounded",
                        fontWeight: 400,
                      }}
                    >
                      Crypto
                    </span>
                    <br />
                    <span
                      className="text-[12px]  text-[#170738ab] "
                      style={{
                        fontFamily: "sf-pro-rounded",
                      }}
                    >
                      Powered by Citypay
                    </span>
                  </p>
                </div>
                {user?.labels?.CRYPTO ? (
                  <>
                    <div className="flex flex-col justify-start items-end">
                      {paintDiscountBadge({
                        labelText: cryptoCampaign[0]?.labelText,
                        labelColor: cryptoCampaign[0]?.labelColor,
                        labelTextColor: cryptoCampaign[0]?.labelTextColor,
                      })}
                      <div
                        style={{
                          color: cryptoCampaign[0]?.labelSubTextColor,
                          minWidth: "66px",
                        }}
                        className="text-[12px] text-right"
                      >
                        {cryptoCampaign[0]?.labelSubText}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center gap-[20px]">
                      <CryptosIcon width={"90"} />
                    </div>
                  </>
                )}
              </div>
            </button>
          </div>
        )}
        {availablePaymentOptions.includes("ob") && (
          <div
            className="flex w-full p-[20px]  rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center  justify-between items-center"
            style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
            onClick={() => {
              setIsObOpen(true);
            }}
          >
            <div>
              <button className="h-[30px]">
                <OnlineBankIcon className="w-[22px] h-[22px]" />
                <p className="text-[16px] ms-[5px]">{t("onlineBank")}</p>
              </button>
            </div>
            {user?.labels?.OB && (
              <>
                <div className="flex flex-col justify-start items-end">
                  {paintDiscountBadge({
                    labelText: obCampaign[0]?.labelText,
                    labelColor: obCampaign[0]?.labelColor,
                    labelTextColor: obCampaign[0]?.labelTextColor,
                  })}
                  <div
                    style={{
                      color: obCampaign[0]?.labelSubTextColor,
                      minWidth: "66px",
                    }}
                    className="text-[12px] text-right"
                  >
                    {obCampaign[0]?.labelSubText}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {availablePaymentOptions.includes("installment") && (
          <div
            onClick={() => {
              changePaymentType("installment");
            }}
            className=" w-full py-[20px] px-[20px] rounded-[16px] flex justify-between items-center bg-[#74748014]"
          >
            <div className="flex items-center gap-[13px] ms-[8px]">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.0989 12.6113C23.0989 7.49481 18.8977 2.41584 12.3575 2.41584C7.07542 2.41584 4.46481 6.12667 3.35238 8.10008H6.65399C7.17897 8.10008 7.60456 8.50404 7.60456 9.00233C7.60456 9.50063 7.17897 9.90459 6.65399 9.90459H0.95057C0.425589 9.90459 0 9.50063 0 9.00233V3.58881C0 3.09051 0.425589 2.68656 0.95057 2.68656C1.47555 2.68656 1.90114 3.09051 1.90114 3.58881V6.86071C3.24055 4.61965 6.33852 0.611328 12.3575 0.611328C20.0814 0.611328 25 6.63007 25 12.6113C25 18.5926 20.0814 24.6113 12.3575 24.6113C8.66194 24.6113 5.58513 23.2233 3.39445 21.1077C2.22152 19.9748 1.30548 18.6366 0.680913 17.1961C0.481103 16.7353 0.7127 16.208 1.19817 16.0184C1.68365 15.8287 2.23918 16.0485 2.43897 16.5093C2.97289 17.7407 3.75466 18.8811 4.75038 19.8427C6.59795 21.6272 9.19194 22.8068 12.3575 22.8068C18.8977 22.8068 23.0989 17.7279 23.0989 12.6113ZM12.3574 6.29559C12.8824 6.29559 13.308 6.69955 13.308 7.19784V12.2377L16.8318 15.5824C17.203 15.9347 17.203 16.506 16.8318 16.8584C16.4606 17.2107 15.8588 17.2107 15.4875 16.8584L11.6853 13.2494C11.507 13.0802 11.4068 12.8507 11.4068 12.6114V7.19784C11.4068 6.69955 11.8324 6.29559 12.3574 6.29559Z"
                  fill="#170738"
                />
              </svg>
              <p className="text-[16px] ">{t("installmentText")}</p>
            </div>
            {!user?.labels?.INSTALLMENT && (
              <p className="bg-[#6C63FF] text-[12px] text-white rounded-[5px] w-[35px] h-[20px] flex justify-center items-center">
                New
              </p>
            )}
            {user?.labels?.INSTALLMENT && (
              <>
                <div className="flex flex-col justify-start items-end">
                  {paintDiscountBadge({
                    labelText: installmetCampaign[0]?.labelText,
                    labelColor: installmetCampaign[0]?.labelColor,
                    labelTextColor: installmetCampaign[0]?.labelTextColor,
                  })}
                  <div
                    style={{
                      color: installmetCampaign[0]?.labelSubTextColor,
                    }}
                    className="text-[12px] text-right"
                  >
                    {installmetCampaign[0]?.labelSubText}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col w-full bg-white font-sans">
        {/* <div className="w-[90%] mx-auto pt-[20px]" /> */}
        <div className="w-full border-[#d5d5d5] pb-8">
          <div className="mx-auto w-[92%] flex justify-between items-center">
            {!isObOpen ? (
              <p className="text-black-500 font-bold text-lg">
                {t("paymentMethod")}
              </p>
            ) : (
              <p className="text-black-500 font-bold text-lg">
                {t("selectBank")}
              </p>
            )}

            <button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 13.15 13.15"
              >
                <path
                  id="close"
                  d="M12,13.4,7.1,18.3a.99.99,0,0,1-1.4-1.4L10.6,12,5.7,7.1A.99.99,0,0,1,7.1,5.7L12,10.6l4.9-4.9a.99.99,0,0,1,1.4,1.4L13.4,12l4.9,4.9a.99.99,0,0,1-1.4,1.4Z"
                  transform="translate(-5.425 -5.425)"
                  fill="#3f2c65"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="w-[92%] mx-auto pb-8 flex flex-col gap-4">
          {content}
        </div>
      </div>
    </>
  );
}

export default SelectPaymentMethod;
