const SkipassTermsPage = () => {
  return (
    <div className="w-full md:px-[200px]">
      <div className={`pt-4 pb-12 px-6 md:px-0}`}>
        <ul className="list-disc pl-6">
          <li>
            Keepz.me-ს ყოველ 50-ე მომხმარებელს საჩუქრად გადაეცემა უფასო დღიური
            Ski-pass
          </li>
          <li>
            იმისათვის, რომ გამარჯვებულმა მომხმარებელმა გაანაღდოს მისთვის
            საჩუქრად გადაცემული Ski-Pass, მას გადაეცემა შესაბამისი პრომო კოდი,
            რომლის განაღდებაც შესაძლებელი იქნება მისთვის სასურველი ski pass-ის
            შეძენის სისტემის საშუალებით - biletebi.ge, MTA, tkt.ge, Skipass App;
          </li>
          <li>დაუშვებელია Ski Pass-ის პრომოკოდის ორჯერ განაღდება</li>
          <li>
            აქცია ძალაშია 2025 წლის 10 იანვრიდან და მოქმედებს 2025 წლის 10
            აპრილის ჩათვლით.
          </li>
          <li>აქცია ვრცელდება მხოლოდ დღიურ სათხილამურო აბონიმენტებზე</li>
          <li>
            გაითვალისწინე, რომ აქცია არ ვრცელდება Apple Pay/ Google Pay-ში
            მიმაგრებული ბარათით გადახდისას
          </li>
          <li>
            კომპანია იტოვებს უფლებამოსილებას, საეჭვო ტრანზაქციის გამოვლენის
            შემთხვევაში არ გაავრცელოს აქციის პირობები ტრანზაქციაზე ან/და
            გააუქმოს იგი
          </li>
          <li>
            კომპანია, ასევე იტოვებს უფლებამოსილებას, ნებისმიერ დროს შეიტანოს
            ცვლილება სააქციო კატეგორიებში, შეცვალოს ან სრულად გააუქმოს აქცია.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkipassTermsPage;
