import { useTranslation } from "react-i18next";

const getSymbol = (curr) => {
  return {
    USD: "$",
    EUR: "€",
    GEL: "₾",
  }[curr];
};
const CurrencyCalculate = ({
  curr,
  acquringCurrency,
  convertedAmount,
  rate,
  isDekstop = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  let currency = getSymbol(acquringCurrency);

  let fromCurr = getSymbol(curr);

  return (
    <>
      {!isDekstop ? (
        <div className="w-[100%] px-[15px] py-[10px] flex items-center justify-between rounded-[13px] border-[1px] border-[#EEF0F3]">
          {" "}
          <div className={`text-left }`}>
            <p className="text-[12px] text-[#8A8A8E]">
              {t("calculateCurrnecy.totalPayable", {
                currency: currency,
              })}
            </p>
            <p className="text-[16px] font-bold">
              {convertedAmount + " " + currency}
            </p>
          </div>
          <div className="text-left me-[20px]">
            <p className="text-[12px] text-[#8A8A8E]">
              {t("calculateCurrnecy.exchangeRate")}
            </p>
            <p className="text-[16px]">
              {"1" +
                " " +
                fromCurr +
                " " +
                "=" +
                " " +
                1 * rate +
                " " +
                currency}
            </p>
          </div>
        </div>
      ) : (
        <div className="text-right text-[#8A8A8E]">
          <p className="text-[16px]">{currency + " " + convertedAmount}</p>
          <p className="text-[12px] mt-[-3px]">
            {"1" + " " + fromCurr + " " + "=" + " " + 1 * rate + " " + currency}
          </p>
        </div>
      )}
    </>
  );
};

export default CurrencyCalculate;
