import {
  ADD_AVAILABLE_PAYMENT_OPTION,
  PRE_DISCOUNT_AMOUNT,
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_CONVERTED_AMOUNTS,
  SET_GPAY_STATE,
  SET_INPUT_ERROR,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_PRODUCTS,
  SET_TOGGLE_INSTALLMENT_MODAL,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./actions";

function paymentReducer(state, { type, payload }) {
  switch (type) {
    case SET_GPAY_STATE:
      return {
        ...state,
        gpayState: payload,
      };
    case SET_APPLE_PAY_STATE:
      return {
        ...state,
        applePayState: payload,
      };
    case SET_LOADING_PAYMENT:
      return {
        ...state,
        loadingPayment: payload,
      };
    case SET_PAYMENT_RESULT:
      return {
        ...state,
        paymentResult: payload,
      };
    case SET_AMOUNT:
      return {
        ...state,
        amount: payload,
      };
    case SET_INPUT_ERROR:
      return {
        ...state,
        inputError: payload,
      };
    case SET_TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: !state.showPaymentModal,
      };
    case ADD_AVAILABLE_PAYMENT_OPTION:
      return {
        ...state,
        availablePaymentOptions: [...state.availablePaymentOptions, payload],
      };
    case SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        selectProducts: payload,
      };
    case SET_TOGGLE_INSTALLMENT_MODAL:
      return {
        ...state,
        showInstallmentModal: !state.showInstallmentModal,
      };
    case SET_CONVERTED_AMOUNTS:
      return {
        ...state,
        convertedAmounts: payload,
      };
    case PRE_DISCOUNT_AMOUNT:
      return {
        ...state,
        preDiscountAmount: payload,
      };

    default:
      return state;
  }
}

export default paymentReducer;
