import useSWR from "swr";
import {
  convertBase64,
  decrypt,
  decryptAES,
  encryptAES,
  generateIV,
  generateKey,
  parseBase64,
  encrypt,
} from "utils/encryption";
import { eCommerceServiceInstance } from "api";

export const useRateData = (integratorId, integratorOrderId) => {
  const { data: { rate = "" } = {} } = useSWR(
    integratorOrderId
      ? `/api/integrator/order/rate/encrypted?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      try {
        const data = {
          integratorId: integratorId,
          integratorOrderId: integratorOrderId,
        };
        const iv = generateIV();
        const secretKey = generateKey();
        const ivBase64 = convertBase64(iv);
        const secretKeyBase64 = convertBase64(secretKey);
        const base64 = `${secretKeyBase64}.${ivBase64}`;
        const encryptedBase64 = encrypt(base64, {
          stringify: false,
          shouldEncode: true,
        });
        const encrypteData = encryptAES(data, secretKey, iv, {
          shouldEncode: true,
        });
        const {
          data: {
            value: { encryptedData, aes, encryptedKeys },
          },
        } = await eCommerceServiceInstance.get(
          url +
            `&encryptedData=${encrypteData}&encryptedKeys=${encryptedBase64}`
        );
        const encrypteSecretKey = decrypt(encryptedKeys, {
          shouldParse: false,
        });
        const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
        const secretkey = parseBase64(base64SecretKey);
        const IV = parseBase64(base64IV);
        const encrypted = decryptAES(encryptedData, secretkey, IV);
        return encrypted;
      } catch (error) {
        console.log("Err");
      }
    }
  );

  return { rate };
};
