import {
  acceptApplePay,
  acceptApplePayTbc,
  postTransactionAction,
  resetFailOrder,
} from "api";
import axios from "axios";
import {
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
} from "features/bog/reducer/actions";
import { JSEncrypt } from "jsencrypt";

// export function validateMerchantUfc(merchantValidationUrl) {
//   const options = {
//     method: "POST",
//     url: `https://keepzpaytbc.ufc.ge:443/applePay/validateMerchant`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       type: "applepay_session",
//       attributes: {
//         url: merchantValidationUrl,
//       },
//     },
//   };
//   return axios.request(options);
// }
const xhrRequest = (type, url, resolve, reject) => {
  let xhr = new XMLHttpRequest();

  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      resolve(JSON.parse(xhr.response));
    } else {
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      });
    }
  };
  xhr.onerror = function () {
    reject({
      status: xhr.status,
      statusText: xhr.statusText,
    });
  };

  xhr.open(type, url);
  xhr.setRequestHeader("Content-Type", "application/json");

  return xhr;
};

const getApplePaySession = (url) => {
  return new Promise((resolve, reject) => {
    const payload = {
      data: {
        type: "applepay_session",
        attributes: {
          url: url,
        },
      },
    };

    xhrRequest(
      "POST",
      "https://keepzpaytbc.ufc.ge:443/applePay/validateMerchant",
      resolve,
      reject
    ).send(JSON.stringify(payload));
  });
};

const startApplePaySessionTbc = (
  {
    transactionIdPromise,
    amount,
    integratorOrderId,
    integratorId,
    dispatch,
    qrType,
    isTip = false,
    setTipError = null,
    acquiringCurrency,
  },
  callback = null
) => {
  const request = {
    countryCode: "GE",
    currencyCode: acquiringCurrency,
    supportedNetworks: ["visa", "masterCard"],
    merchantCapabilities: ["supports3DS"],
    total: { label: "Keepz", amount: amount.toString() },
  };
  let genericId = "";
  let merchantTransactionId = "";
  let startPaymentError = undefined;
  const session = new window.ApplePaySession(10, request);
  session.onvalidatemerchant = async function (event) {
    const { validationURL } = event;

    transactionIdPromise
      .then((data) => {
        merchantTransactionId = data.transactionId;
        genericId = data.genericId;
        getApplePaySession(validationURL)
          .then((response) => {
            session.completeMerchantValidation(
              JSON.parse(response.data.attributes.session)
            );
          })
          .catch((err) => {
            session.abort();
            if (!isTip) {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "",
                  group: 4,
                },
              });
            } else if (isTip) {
              setTipError({
                show: true,
                type: "",
                group: 4,
              });
            }
          });
      })

      .catch((err) => {
        startPaymentError = err;
        session.abort();
        const result = {
          status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
        };
        if (callback) {
          callback(result);
        }
      });
  };

  session.onpaymentmethodselected = (event) => {
    try {
      const update = {
        newTotal: { label: "Keepz", amount },
      };

      session.completePaymentMethodSelection(update);
    } catch (error) {}
  };

  session.onshippingmethodselected = (event) => {
    try {
      const update = {};
      session.completeShippingMethodSelection(update);
    } catch (error) {}
  };

  session.onpaymentauthorized = async (event) => {
    const { payment } = event;
    const payload = {
      data: {
        type: "payment",
        attributes: {
          apple_pay: {
            token: payment.token,
            shipping_contact: payment.shippingContact,
            application_data: merchantTransactionId,
            payment_request: request,
          },
        },
      },
    };

    const acceptPayment = acceptApplePayTbc(payload);
    let status = {
      status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    };

    acceptPayment
      .then(({ data }) => {
        if (data?.attributes?.status == "FINISHED") {
          status = window.ApplePaySession.STATUS_SUCCESS;
        }
        const result = {
          status,
          transactionId: merchantTransactionId,
        };
        session.completePayment(result);

        if (callback) {
          callback(result, genericId);
        }
      })
      .catch((err) => {
        session.abort();
        async function failRedirct() {
          if (integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
        }
        failRedirct();
        if (!isTip) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.statusCode,
              group: err?.exceptionGroup,
            },
          });
        } else if (isTip) {
          setTipError({
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          });
        }
      });
  };

  session.oncancel = function (event) {
    if (integratorOrderId && qrType != "REQUEST") {
      resetFailOrder({
        integratorId: integratorId,
        integratorOrderId: integratorOrderId,
      })
        .then((data) => {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 100,
              group: 1,
            },
          });
        })
        .catch((err) => console.log(err, "error occured while resetting"));
    }

    dispatch({ type: SET_LOADING_PAYMENT, payload: false });
    // const result = {
    //   status: startPaymentError || window.ApplePaySession.STATUS_FAILURE,
    // };
    // if (callback) {
    //   callback(result);
    // }
  };
  session.begin();
};

export default startApplePaySessionTbc;
