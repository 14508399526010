import { rsCreate } from "api";
import { useQueryString } from "hooks";
import { useEffect } from "react";

const RsGe = () => {
  const { parseQuery } = useQueryString();
  const {
    integrator_id,
    merchant_id,
    payment_id,
    success_url,
    fail_url,
    lang,
  } = parseQuery;
  useEffect(() => {
    const payload = {
      paymentId: payment_id,
      integratorId: integrator_id,
      merchantId: merchant_id,
      successUrl: success_url,
      failUrl: fail_url,
      lang: lang == "ka" ? "ge" : "en",
    };
    rsCreate(payload).then((data) => window.open(data?.urlForQR, "_self"));
  }, []);
  return <div className="w-full h-full bg-white"></div>;
};

export default RsGe;
