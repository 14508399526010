import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          selectBank: "Select bank",
          receipt: "Receipt",
          paid: "was paid on",
          calculateCurrnecy: {
            totalPayable: "Total Payable in {{currency}}:",
            exchangeRate: "Exchange rate:",
            totalDesktop:
              "Total Payable {{paymentMethod}}: {{amount}} {{currency}} ",
          },
          campaign: {
            shop1: "Ski and Snowmobile Rental",
            shop1Location: "Maya Zakhaidze, near VISTA Hotel",
            shop2: "Ski Rental",
            shop2Location: "Paata Buthuzi, near VISTA Hotel",
            shop3: "Night Club Chrdili",
            shop3Location: "New Gudauri",
            shop4: "Cafe FRESH FOOD",
            shop4Location: "near VISTA Hotel",
            shop5: "Instructor",
            shop5Location: "Niko Jermizashvili, New Gudauri",
            shop6: "Ski Rental CHAMPION",
            shop6Location: "New Gudauri",
            shop7: "Ski Rental SKI BOOM",
            shop7Location: "New Gudauri",
            shop8: "Snowmobile Rental",
            shop8Location: "Diana Barbakadze, New Gudauri",
            shop9: "Snowmobile Rental",
            shop9Location: "Gogita Karumidze, New Gudauri",
            shop10: "Instructor",
            shop10Location: "Nikoloz Nazghaidze, New Gudauri",
            shop11: "Taxi",
            shop11Location: "Davit Zakhaidze, New Gudauri",
            shop12: "Instructor",
            shop12Location: "Kristine Bekauri, New Gudauri",
            shop13: "Snowmobile Rental",
            shop13Location: "Soso Tzomaia, near VISTA Hotel",
            shop14: "Ski Rental / Instructor",
            shop14Location: "Irakli Shonia, Marco Polo Hotel",
            shop15: "Snowmobile Rental",
            shop15Location: "Davit Qamarauli, New Gudauri",
            shop16: "Instructor",
            shop16Location: "Revaz Nazghaidze, New Gudauri",
            shop17: "Instructor",
            shop17Location: "Giorgi Zhuzhunashvili, New Gudauri",
            shop18: "Instructor",
            shop18Location: "Vasili Zakhaidze, New Gudauri",
            shop19: "Instructor",
            shop19Location: "Mikheil Gorelashvili, New Gudauri",
            shop20: "Instructor",
            shop20Location: "Giorgi Khubuluri, New Gudauri",
            shop21: "Instructor",
            shop21Location: "Kote Oniani, New Gudauri",
            shop22: "Ski Rental / Instructor",
            shop22Location: "Giorgi Melikishvili, Old Gudauri",
            shop23: "Cafe Pirveli",
            shop23Location: "Old Gudauri",
            gudauri: "Gudauri",
            bakuriani: "Bakuriani",
            fir: "*Participation in the promotion is only available to MasterCard cardholders.",
            wishULuck: "Best of luck!",
            firstBullet:
              "Make a payment using the Keepz QR terminal with your preferred method.",
            secondBullet:
              "Wait for the payment confirmation, and every 50th payer is guaranteed to win a one-day Skipass.",
            thirdBullet:
              "In case of winning, choose your preferred platform where you can redeem the unique promo code (tkt.ge, biletebi.ge, Skipass app, M.T.A. cashier).",
            fourthBullet:
              "After making a selection, you will receive a unique promo code, which you can use on the platform of your choice to get one day of Skypasis for free.",
            rules: "Giveaway Rules:",
            winSkipass: "Pay with a QR terminal and win a SKIPASS.",
            smsSent: "SMS Sent to your number!",
            showCodeTwo:
              "Use this promo code at checkout <br/> on {{ vendor }} to get a free skipass!",
            showCode:
              "Simply present this code at the Skipass <br/>  cash desk to claim your prize.",
            congrats: "Congratulations! <br/> You’ve won a Skipass!",
            buttonSend: "Send code via SMS",
            buttonClose: "Close",
            storesToWin: "Addresses where you can win Skipass",
            vasilZakaidze: "Vasil Zakaidze",
            irinaAbashidze: "Irina Abashidze",
            buranRental: "Burani rental / Paragliding",
            gigaSeturidze: "Giga Seturidze, New Gudauri",
            davidZakaidze: "David Zakaidze, New Gudauri",
            irakliWamaldze: "Irakli Tsamaladze, New Gudauri",
            gochaBedoidze: "Gocha Bedoidze, New Gudauri",
            gigaKavtaradze: "Giga Kavtaradze, New Gudauri",
            koba: "Koba Mikorashvili, New Gurdauri",
            buggyRental: "Instructor/Buggy rental",
            gela: "Gela Tsamaladze, New Gudauri",
            lukaJ: "Luka Jerminzashvili, New Gudauri",
            nugzarBedoidze: "Nugzar Bedoidze, New Gudauri",
            valerian: "Valerian Bedoidze, New Gudauri",
            paraGlidInst: "Paragliding instructor",
            ketevanN: "Ketevan Nazgaidze, New Gudauri",
            sandroJaniashvili: "Sandro Janiashvili, New Gudauri",
            nodarTsiklauri: "Nodar Tsiklauri, New Gudauri",
            passengerTrans: "Passenger transportation",
            davidJaniashvili: "David Janiashvili, New Gudauri",
            passengerTransWithDelic: "Passenger transportation with Delic",
            dimitriSeturidze: "Dimitri Seturidze, New Gudauri",
            davBedoidze: "David Bedoidze, New Gudauri",
            maia: "Maia Avsajanashvili, New Gudauri",
          },
          payerPersonalNumberError: "Personal number should contain 11 digits",
          amount: "Amount",
          amountToPay: "Amount to pay",
          termsAndCondition: "Terms and conditions",
          privacyPolicy: "Privacy policy",
          selectBank: "Select bank",
          onlineBank: "Online bank",
          learnMore: "Learn more",
          tip: "Tip",
          comment: "Comment",
          installmentText: "Divide into 4 parts",
          inputs: {
            noneResident: "None-resident",
            payerName: "Payer name",
            additionaDescription: "Additional description",
            enterNumber: "Enter your number",
            description: "Description",
            payerPersonalNumber: "Payer personal number",
            personalOrPassport: "Personal or passport number",
          },
          qrTerminal: {
            qrNavigation: {
              terminalNavTitle1: "Unique QR",
              terminalNavDescription1:
                "A unique QR code per merchant, which enables easy access to payments.",
              terminalNavTitle2: "Real time",
              terminalNavDescription2: "24/7 settlement on any bank account",
              terminalNavTitle3: "No registration needed",
              terminalNavDescription3:
                "Your customers only need access to a smartphone and the internet.",
              terminalNavTitle4: "Tips and ratings",
              terminalNavDescription4:
                "Ratings and tips, available after each payment",
              terminalNavTitle5: "Integrations",
              terminalNavDescription5:
                "Integrated to all major ERP<br>systems available in Georgia",
              terminalNavTitle6: "Payment methods",
              terminalNavDescription6:
                "All popular payment methods: Apple Pay, Google Pay, Crypto, Mobile banks, etc.",
            },
            qrHowTo: {
              title: "how it works?",
              placeQrTerminal: "Place QR terminal",
              placeQrTerminalDesc1:
                "Place a QR terminal in your store for seamless payments.",
              placeQrTerminalDesc2:
                "Users simply scan, enter the amount, and pay. Alternatively, <strong>integrate with ERP programs </strong>",
              placeQrTerminalDesc2Span: "(Fina, Optimo, Balance, Apex)",
              placeQrTerminalDesc3:
                " to automatically display the payment amount on the smartphone screen when scanned, just like a traditional terminal. ",
              placeQrTerminalDesc4:
                "For restaurants, <strong>place a QR code on the table</strong> for automatic check payment.",
              trackPayments: "Track transactions <br>in real time",
              trackPaymentsDesc1:
                "All enrollments are instantly reflected in both the application and the dashboard.",
              trackPaymentsDesc2:
                "You can see who paid, when, and how much, as well as the total enrollments for the day, month, or a specific period.",
              trackPaymentsDesc3:
                "Invoices for each payment can be generated and downloaded.",
              getCustomerFeedback: "Receive rating, <br>feedback and tips",
              getCustomerFeedbackDesc1:
                "Customers have the ability to rate you and leave tips.",
              getCustomerFeedbackDesc2:
                "You can view your average rating on your profile, and received tips will appear in your transactions.",
              manageBranches: "Manage all your branches from <br>one dashboard",
              manageBranchesDesc1:
                "On the dashboard, you can dynamically monitor your company's key financial metrics, current and historical transactions, customer feedback, and more.",
              manageBranchesDesc2:
                "Most importantly, <strong>you can add, edit, hide, or delete branches</strong>. You can choose to have funds deposited centrally or assign different bank accounts to each branch as needed.",
            },
            qrUserFeedback: {
              userFeedbackAboutUs: "Our merchants about us",
              tamarMaisuradze: "Tamar Maisuradze",
              vasoKhatiashvili: "Vaso Khatiashvili",
              operationsDirector: "Operations Director",
              ninoMurvanidze: "Nino Murvanidze",
              generalManager: "General Manager",
              ninoKobakhidze: "Nino Kobakhidze",
              foodStore: "Chief accountant",
              vasoKhatiashviliFeedback:
                "“Integration with keepz simplified the process of leaving a tip and, one might say, encouraged it. The inconvenience associated with communication related to the amount of money has been removed. Keepz is also very convenient when the guest wants to leave a tip after closing the account. It is equally tailored to the customer as well as to us.”",
              ninoMurvanidzeFeedback:
                "“Many of our customers are foreigners and a payment method that is not tied to a specific bank turned out to be more convenient. Cooperation with keepz is beneficial for both parties, observation has shown that a large number of users choose keepz as a payment method.”",
              ninoKobakhidzeFeedback:
                "“Integration with Keepz has simplified the process of paying with crypto assets. For most consumers, paying for their drinks with crypto-assets is welcome news. Keepz allowed us to adapt to their different interests and make our business more innovative.”",
            },
          },
          leaveInfo: "Leave <br> contact <br> information",
          name: "Name",
          companyName: "Company name",
          mobileNumber: "Mobile number",
          howQrTerminal: "How to use QR terminal",
          requestedPayment: "Requested payment",
          enterAmount: "Enter amount",
          paymentMethod: "Payment Method",
          retry: "Retry",
          paymentProcessing: "Payment is processing",
          keepz: "Keepz",
          why: "Why",
          tenTime: "10x",
          qrCheck: {
            firstBullet: "Print the payment QR directly on the receipt",
            secondBullet:
              "Customers can easily pay, leave tips, rate their experience, add comments, and quickly leave the restaurant.",
            thirdBullet:
              "The QR code is integrated with your ERP/POS system, and payments are automatically recorded.",
            fourthBullet:
              "Ideal for restaurants, hotels and similar establishments",
          },
          qrPayment: {
            firstBullet: "The customer scans the QR code on their table.",
            secondBullet:
              "They view their current bill and the total amount due.",
            thirdBullet:
              "The customer clicks on the payment option and pays using any available method through Keepz.",
            fourthBullet:
              "This enhances the customer experience and saves time.",
          },
          price: {
            pricing: "pricing",
            fixedCommision: "Fixed <br> fee",
            fixedCommisionDesc:
              "No fixed fees, unless the commission reaches 5₾ per month.",
            mobilebank: "Mobile bank",
            openBanking: "(Open banking)",
            commision: "Commision",
            cryptoCurrency: "Crypto ",
            bankCards: "Bank cards",
            haveQuestions: "Have questions? Talk to us",
            leaveNumber: "Leave us your number, we will call you shortly",
          },
          priceDesc:
            "more affordable  compared <br/>  to traditional  POS systems",
          priceDescTwo:
            "Experience innovation affordably: try our game-changing <br/> payment method for just 1/10th the price!",
          whyKeepz: {
            erp: "QR terminal integrated to <br>  ERP systems - (SKA)",
            qrTerminal: " QR terminal for neighborhood store",
            qrTerminalBeautySalon: "QR terminal for beauty salon (Tenten)",
            parkingKeepz: "Pay parking with Keepz - Lilo mall",
            cryptoPay: "Payment with crypto",
            taxiQr: "QR payment in Taxi",
            useagExample: "Use cases",
            versatile: "Versatile",
            versatileDesc:
              "With just one QR code, accept payments effortlessly via Apple Pay, Google Pay, bank transfers, cryptos, and more – no additional devices required!",
            affordable: "Free",
            affordableDesc:
              "No fixed fees and 0% commission on payments where you decide who pays the commission.",
            fast: "Instant",
            fastDesc:
              "24/7 income reflection in the recipient's account in main Georgian banks.",
            multilevel: "All in one",
            multilevelDesc:
              "One QR code allowing to pay with Bank Cards, Apple/google pay, Crypto, Bank/eWallet/Loyalty apps etc.",
          },
          amountOfCustomersMd:
            "Over 200 businesses are already utilizing Keepz for seamless payments!",
          amountOfCustomers:
            "Over 200 businesses are already utilizing <br/> Keepz for seamless payments!",
          buttons: {
            integration: "Integration",
            reachOut: "Reach out",
            seeVideo: "Open video",
            send: "Send",
            price: "Price",
            getStarted: "Get started",
            request: "Request",
            seeAll: "See all",
            reqCall: "Request call",
          },
          navigation: {
            products: "Products",
            price: "Price",
            howGetIn: "How to use",
            integration: "Integration",
            forBusiness: "For Business",
            logIn: "Log in",
            check: "Check",
          },
          header: {
            title: "Your Innovative Digital <br> Payment Solution ",
            titleNext: "For Free",
            description:
              "Receive payments without the need for a physical terminal or user registration. Instantly reflect income in your bank account and enjoy accessibility everywhere.",
          },
          register: {
            register: "Register",
            title: "Getting started easily",
            download: "1. Download",
            downloadDesc:
              "Download the app or contact our sales team to register with your mobile number.",
            getQR: "2. Get QR",
            getQRDesc: "Get and share your QR to receive payments.",
            start: "3. Start",
            startDesc: "Start accepting payments seamlessly through Keepz.",
          },
          requestAndSend: {
            title: "Or simply request QR, <br/> and we’ll send it over!",
            description:
              "Simply provide your contact information, and we'll deliver <br/> Keepz right  to your doorstep, empowering you to elevate your <br/> payment experience effortlessly.",
            descriptionSm:
              "Simply provide your contact information, and we'll deliver  Keepz right  to your doorstep, empowering you to elevate your  payment experience effortlessly.",
          },

          footer: {
            menu: "Menu",
            location: "13 Mikheil Tamarashvili, Tbilisi 0179 ",
            whyKeepz: "Why Keepz",
            developers: "For developers",
            company: "Company",
            downloadApp: "Download APP",
          },

          statistic: {
            title: "The fastest-growing <br/> payment technology",
            successfulTransfer: " successful  transfers monthly",
            onBoardedMerchants: " onboarded merchants",
            serviceUptime: "service uptime",
            satisfactionRate: "satisfaction rate",
          },

          forBusiness: {
            keepzForbusiness: "KEEPZ for business",
            getProfit: "Get benefits by partnering with us",
            qrBank: "QR for <br/> mobile banking",
            qrBankTwo: "Pay on every Keepz QR terminal",
            qrBankDe:
              "Embed Keepz QR reader in your financial application. Allow your customers to pay with your app wherever they are, and benefit from every transaction.",
            qrBankForWho: "Who is it for?",
            firstBullet: "Mobile banks",
            secondBullet: "Digital wallets",
            thirdBullet: "Loyalty apps",
            fourthBullet: "Other digital payment platforms",
            qrAgent: "QR <br/> agent",
            qrAgentTwo:
              "Place a Keepz QR terminal in your merchant's facilities",
            qrAgentDesc:
              "Place Keepz QR terminal in your merchant's facilities and",
            qrAgentSpan: "earn 0.25% of every payment.",
          },

          paymentTypes: {
            selectPayment: "Select payment method",
            forMerchants: "for merchants",
            paymentsForAnyOcasion: "Payments for any occasion",
            paymentsForAnyOcasionSm: "Payments for <br/> any occasion",
            becomeOurPartner: "Become our partner",
            forFinancialInstitutions: "for financial institutions",
            terminalForMerchant: {
              title: "QR terminal",
              titleB: "QR <br/> terminal",
              headLines: "Receiving payments made easy",
              description:
                "Do you own a store, salon, or work as a street vendor, handling product sales or customer transactions? Accept contactless payments quickly and easily. Discover the innovative capabilities of our QR terminal.",
            },
            qrForMobileBanks: {
              title: "QR for mobile banking",
              headLines: "Pay directly from the mobile banking app",
            },
            individualPayment: {
              title: "Individual payment",
              titleB: "Individual <br/> payment",
              headLines: "Get paid quickly and easily",
              description:
                "Keepz’s individual billing allows you to quickly and easily get paid for the work you've done. It doesn't matter if you are a craftsman, a taxi driver, a cleaner or any service provider - Keepz makes it easy for you to receive money.",
            },

            distancePayment: {
              title: "Link payment",
              titleB: "Link <br/> payment",
              headLines: "Receive payments from anywhere, anytime",
              description:
                "Experience the freedom and flexibility of distance payment with Keepz. Send payment links to customers and receive payments. The process is the simplest and only takes a few seconds. You will receive a notification about successful payment instantly.",
            },
            QRcheck: {
              title: "QR <br/> receipt",
              titleB: "QR  receipt",
              headLines: "Payment QR right on the receipt",
              description:
                "Make it easier to get tips with  Keepz. It doesn't matter what field you are in - whether you are a waiter, bartender, courier, fueler or driver - increase your income with a seamless payment experience, receive tips digitally, without paying cash directly to your bank account.",
            },
            qrForRestaurant: {
              title: "QR payment for restaurants",
              titleB: "QR payment <br/> for restaurants",
              headLines: "Menu and payment in one solution",
              description:
                "Experience efficient payments with Keepz's integrated payment. Receive payments right in the store's POS system. Add Keepz as one of the payment methods in your store's accounting software.",
            },
            onlinePayments: {
              title: "Ecommerce payments",
              titleB: "Ecommerce <br/> payments",
              headLines: "One integration - many payment methods",
              description:
                "Unlimited possibilities to receive digital payments. Get simplified payments for your online store. With one simple integration, you allow the user to pay by bank card, Apple Pay, Google Pay and cryptocurrency.",
            },
            pay2keepz: {
              title: "⁠PAY-2-KEEPZ",
              headLines: "Pay anywhere where there is a Keepz QR",
              description:
                "Embed Keepz SKD in your financial application. Give your customers the ability to pay with your app wherever they are. In return, get the benefit of every payment.",
            },
            keepzSubMerchant: {
              title: "KEEPZ SUB-MERCHANT",
              titleB: "KEEPZ <br/> SUB-MERCHANT",
              headLines: "Place Keepz on your client's facilities",
              description:
                "Get your merchants to post Keepz’s QR in their facilities, in return get a profit from every payment.",
            },
          },
          description: {
            ibanIsBlocked:
              "Payment to this recipient is not allowed. Please contact Keepz support for assistance.",
            washerError: "Device is currently unavailable",
            issuerError: "Transaction declined by your bank. Contact your bank",
            generalError: "Card error. Try again",
            authFailed: "Authentication failed by client device",
            cardNotSupported: "Card type not supported. Use another card",
            cardExpired: "Card expired. Use a valid card",
            cardBlocked: "This card is blocked. Contact your bank",
            cardNotFound: "Card not found. Check details and retry",
            invalidCardData: "Card details are incorrect",
            notEnoughFunds: "Insufficient funds",
            paymentCancelled: "Payment cancelled",
            paymentCancelledDesc:
              "Please contact the operator to create a new one",
            payProgress: "Payment in progress please try again later",
            orderInProgress: "Order in Progress",
            limitsProblem: "Limit issue, please try again",
            informationNotFound: "information not found, please try again",
            resourcesNotFound: "Data not found, please try again",
            formatError: "Format error",
            provideError: "Provider error, please try again.",
            mistake: "An error occured, please try again .",
            informationIsNotValid: "Information is insufficent or invalid",
            recipientBlocked: "Recipient blocked",
            beneficarylimit:
              "Recipient surpassed limit\nfor receiving payments",
            productExpired: "Link is expired",
            orderExpired: "Order expired",
            noValid: "Order is not valid anymore",
            paymentProcessing:
              "Transaction is being processed and\nwill be confirmed shortly.",
            continueWithApp: "Continue with app",
            continueAsGuest: "Continue as guest",
            pay: "Pay",
            sendTips: "Send tips, within a few taps",
            customAmount: "Custom Amount",
            OpenLinkFromMobile: "Please open link from mobile",
            transactionComplete: "Transaction Complete",
            yourTipWasSentToServer: "Your payment was successful!",
            paymentSuccessful: "Payment is successful!",
            paymentSuccessfulInfo: "{{amount}} {{currency}} was sent to",
            paymentIsPending: "{{amount}} {{currency}} is sending to",
            transactionFailed: "Transaction Failed",
            gateError: "Gate is temporarily unavailable",
            limitError: "Payment must be within the limit.",
            qrCheckError: "Amount is not set by merchant\nTry again.",
            orderPaid: "The order is already paid",
            paymentProgress: "Payment in progress",
            tryAgain: "Please try again later",
            paymentProgressTwo: "Payment in progress",
            pleaseCheckFunds: "Payment was not completed, please try again.",
            pleaseCheckFundsV2:
              "Please make sure you enter correct card details and have sufficient funds.",
            enterAmount:
              "Please enter amount from {{ minValDB }}{{currency}} to {{ maxValDB }}{{currency}} ",
            totalPayable:
              "Total payable including fees {{ total }} {{currency}}",
            transactionProcessing: "Transaction has been processed",
            youWillGetSMS: "You will get SMS once your card gets charged",
            agreetc: `By clicking pay you  agree to`,
            termsAndCon: "our terms & conditions.",
            limitHeader: `Monthly limit reached`,
            limitBody: `The recipient has reached the monthly limit for receiving payments this month.`,
            limitBodyTwo: `The recipient has reached the daily limit for receiving payments this month.`,
            payzeContinueWith: `Apple Pay`,
            payzePayWithCard: `Bank card`,
            somethingWentWrong: `Something went wrong`,
            weReWorkingOnGettingThisFixed: `We’re working on getting this fixed`,
            done: "Done",
            yourTipWasSuccessfullySentToYourServersAccount: `Your payment was successful!`,
            serviceAccount: "Your Server's Account",
            skip: "Skip",
          },
          ratings: {
            Terrible: "Terrible",
            Bad: "Bad",
            Normal: "Normal",
            Good: "Good",
            Great: "Great",
            pleaseRate: "Please Rate",
            thanksForFeedback: `Thank you\nfor your feedback!`,
          },
          privacy: {
            title: "Privacy Policy",
            intro: "Introduction",
            intro1: `This Personal Data Processing Policy (hereinafter – the Policy) sets
            out the ways in which we collect, use, disclose, and protect personal
            data in connection with the payment transaction execution and account
            registration features of our mobile application – Keepz (the
            "Service"). We take the protection of personal data seriously and are
            committed to complying with applicable data protection laws. The
            present Personal Data processing policy is governed by the Law of
            Georgia on the Processing of Personal Data. For the purposes of the
            mentioned law, BKN301 GEORGIA LLC shall act as the Data Processor and the Client
            shall be the Data Subject.`,
            intro2: `By accessing and/or using the Keepz and/or BKN301 GEORGIA LLC services, you
            acknowledge and confirm that you have read, understood, and agree to
            this Personal Data Processing Policy. Also, after you register with
            the Keepz App and start using our services, this Policy becomes a
            Supplement to the Terms and Conditions.`,
            intro3: `BKN301 GEORGIA LLC has the right to modify this Policy at its sole discretion
            and at any time by publishing on the website, or otherwise making it
            available to the Client. The amended version of this Policy shall take
            effect upon publication.`,
            goals: "Purposes",
            goals1: `The main purpose for which BKN301 GEORGIA LLC collects your personal data is to
            provide the payment services to clients. Being the payment service
            provider regulated under the legislation of Georgia, BKN301 GEORGIA LLC is
            required by law to establish and verify identity of the Client prior
            to entering into business relationship, also, at the time of the
            provision of the services, to request further information, as well as
            assess and store this information for the retention period set out by
            legislation. Taking this into account, you are obliged provide correct
            and complete information.`,
            collectData: "Data Collection",
            collectData1: `We may collect the following types of personal data from users of the
            Service:`,
            dataList1: `Information about person, including name, last name, photo, date
            of birth, passport number, national id number, driver's license
            number, employment history, job title.`,
            dataList2: `Contact information, including email address, and telephone
            number.`,
            dataList3: `Payment information, including bank account number, or other
            payment method information.`,
            dataList4: `Account information, including username and password.`,
            dataUsage: `Data Use`,
            dataUsageIntro: `We use personal data collected from users of the Service for the
            following purposes:`,
            dataUsageList1: `To perform payment transactions.`,
            dataUsageList2: `To create and manage user accounts.`,
            dataUsageList3: `To improve the Service and provide better service to users.`,
            dataUsageList4: `To comply with legal and regulatory requirements`,
            dataDisclosure: "Data Disclosure",
            dataDisclosureIntro: `We may disclose personal data collected from users of the Service to
            third parties for the following purposes:`,
            dataDisclosureList1: `To financial institutions and payment processors for the purpose
            of processing payments`,
            dataDisclosureList2: `To service providers that assist us in operating the Service`,
            dataDisclosureList3: `To comply with legal and regulatory requirements`,
            dataPrivacy: `Data Security`,
            dataPrivacyIntro1: `BKN301 GEORGIA LLC intends ensure the highest level of security for all
            information obtained from the Client and public data files. In order
            to protect this information from unauthorized access, use, copying,
            accidental or unlawful erasure, alteration, or disclosure, as well as
            from any other unauthorized form of processing, BKN301 GEORGIA LLC applies
            appropriate legal, administrative, technical, and physical security
            measures.`,
            dataPrivacyIntro2: `The Data shall be processed on the territory of Georgia. For the
            purpose of data security the remote servers of Amazon web services
            will be used.`,
            dataPrivacyIntro3: `Name: Amazon Web Services EMEA SARL`,
            dataPrivacyIntro4: `Address: 38 Avenue John F. Kennedy, L-1855, Luxembourg`,
            dataPrivacyIntro5: `Contact person’s name, position and contact details: AWS EMEA Legal`,
            dataPrivacyIntro6: `Description of processing is available at`,
            dataPrivacyIntro7: `governed under the DPA, which is compliant with the GDPR requirements
            and incorporates updated SCC:`,
            dataPrivacyIntro8: `In no event shall BKN301 GEORGIA LLC process information in any jurisdiction
            outside Georgia or the European Economic Area.`,
            saveData: "Data Retention",
            saveDataIntro: `We retain personal data collected from users of the Service for as
            long as necessary to fulfill the purposes for which it was collected,
            as required by law, or as necessary to comply with our legal
            obligations.`,
            organizeTech: `Organizational and Technological Measures`,
            organizeTechIntro: `BKN301 GEORGIA LLC is implementing information security measures that are
            sufficient to mitigate the risks associated with the data that is
            processed by it in the process of execution of payments. The
            information that is exchanged with the third parties is encrypted by
            SSL. The access to the data stored on the remote servers is strictly
            guarded and only authorized employees have the right to access it in
            case there is such a need. Such employees are bound by the
            confidentiality arrangement which is part of their employment
            contract. The information stored on AWS remote servers is logically
            separated and encrypted and no third parties can access, alter,
            destroy or otherwise compromise it. All the actions in relation to
            data are logged and retained.`,
            dataAccess: "Data Access and Correction",
            dataAccessIntro: `Users of the Service have the right to access, correct, or delete
            their personal data that we hold. They can do this by logging into
            their account, or by contacting us using the contact information
            provided below, unless otherwise provided by Georgian legislation.`,
            contact: "Contact Us",
            contactIntro: `If you have any questions about this Policy or our data protection
            practices, please contact us at email address`,
          },
        },
      },
      ge: {
        translation: {
          selectBank: "აირჩიე ბანკი",
          receipt: "ქვითარი",
          paid: "გადახდილია",
          calculateCurrnecy: {
            totalPayable: "სულ გადასახდელი {{currency}}:",
            exchangeRate: "ვალუტა:",
          },
          campaign: {
            shop1: "თხილამურების და ბურანების გაქირავება",
            shop1Location: "მაია ზაქაიძე, სასტუმრო VISTA-ს მიმდებარედ",
            shop2: "თხილამურების გაქირავება",
            shop2Location: "პაატა ბუთხუზი, სასტუმრო VISTA-ს მიმდებარედ",
            shop3: "ღამის კლუბი ჩრდილი",
            shop3Location: "ახალი გუდაური",
            shop4: "კაფე FRESH FOOD",
            shop4Location: "სასტუმრო VISTA-ს მიმდებარედ",
            shop5: "ინსტრუქტორი",
            shop5Location: "ნიკო ჯერმიზაშვილი, ახალი გუდაური",
            shop6: "თხილამურების გაქირავება CHAMPION",
            shop6Location: "ახალი გუდაური",
            shop7: "თხილამურების გაქირავება SKI BOOM",
            shop7Location: "ახალი გუდაური",
            shop8: "ბურანის გაქირავება",
            shop8Location: "დიანა ბარბაქაძე, ახალი გუდაური",
            shop9: "ბურანის გაქირავება",
            shop9Location: "გოგიტა ქარუმიძე, ახალი გუდაური",
            shop10: "ინსტრუქტორი",
            shop10Location: "ნიკოლოზ ნაზღაიძე, ახალი გუდაური",
            shop11: "ტაქსი",
            shop11Location: "დავით ზაქაიძე, ახალი გუდაური",
            shop12: "ინსტრუქტორი",
            shop12Location: "ქრისტინე ბექაური, ახალი გუდაური",
            shop13: "ბურანის გაქირავება",
            shop13Location: "სოსო ცომაია, სასტუმრო VISTA-ს მიმდებარედ",
            shop14: "თხილამურების გაქირავება / ინსტრუქტორი",
            shop14Location: "ირაკლი შონია, სასტუმრო მარკო პოლო",
            shop15: "ბურანის გაქირავება",
            shop15Location: "დავით ყამარაული, ახალი გუდაური",
            shop16: "ინსტრუქტორი",
            shop16Location: "რევაზ ნაზღაიძე, ახალი გუდაური",
            shop17: "ინსტრუქტორი",
            shop17Location: "გიორგი ჟუჟუნაშვილი, ახალი გუდაური",
            shop18: "ინსტრუქტორი",
            shop18Location: "ვასილი ზაქაიძე, ახალი გუდაური",
            shop19: "ინსტრუქტორი",
            shop19Location: "მიხეილ გორელაშვილი, ახალი გუდაური",
            shop20: "ინსტრუქტორი",
            shop20Location: "გიორგი ხუბულური, ახალი გუდაური",
            shop21: "ინსტრუქტორი",
            shop21Location: "კოტე ონიანი, ახალი გუდაური",
            shop22: "თხილამურების გაქირავება / ინსტრუქტორი",
            shop22Location: "გიორგი მელიქიშვილი, ძველი გუდაური",
            shop23: "კაფე პირველი",
            shop23Location: "ძველი გუდაური",
            gudauri: "გუდაური",
            bakuriani: "ბაკურიანი",
            fir: "*პრომო აქციაში მონაწილეობა შეუძლიათ მხოლოდ მასტერ ქარდის ბარათის მფლობელებს.",
            wishULuck: "გისურვებთ წარმატებებს!",
            firstBullet:
              "გადაიხადე თანხა Keepz QR ტერმინალის საშუალებით, შენთვის სასურველი მეთოდით",
            secondBullet:
              "დაელოდე გადახდის დასტურს, ყოველი 50-ე გადამხდელი გარანტირებულად იგებს ერთ დღიან სკიპასს. ",
            thirdBullet:
              "მოგების შემთხვევაში, აირჩიე სასურველი პლატფორმა სადაც გაანაღდებ უნიკალურ პრომო კოდს. (tkt.ge, biletebi.ge, Skipass app, M.T.A. სალარო).",
            fourthBullet:
              "არჩევის შემდეგ მიიღებ უნიკალურ პრომო კოდს, რომელსაც გამოიყენებ შენს მიერ არჩეულ პლატფორმაზე, ერთ დღიანი სკიპასის უფასოდ მისაღებად.",
            rules: "გათამაშების წესები:",
            winSkipass: "გადაიხადე ქრ ტერმინალით და მოიგე SKIPASS",
            smsSent: "SMS გამოგზავნილია თქვენს ნომერზე!",
            showCodeTwo:
              "გამოიყენე პრომო კოდი <br/> {{ vendor }}-ზე skipass-ის გასააქტიურებლად!",
            showCode:
              "წარადგინეთ ეს კოდი სალაროსთან <br/> და მიიღეთ კუთვნილი საჩუქარი.",
            congrats: "გილოცავთ! <br/> თქვენ მოიგეთ Skipass",
            buttonSend: "Send code via SMS",
            buttonClose: "დახურვა",
            storesToWin: "მისამართები, სადაც შეგიძლიათ მოიგოთ Skipass",
            vasilZakaidze: "ვასილ ზაქაიძე",
            irinaAbashidze: "ირინა აბაშიზე",
            buranRental: "ბურანის გაქირავება/პარაპლანი",
            gigaSeturidze: "გიგა სეთურიძე, ახალი გუდაური",
            davidZakaidze: "დავით ზაქაიძე, ახალი გუდაური",
            irakliWamaldze: "ირაკლი წამალაძე, ახალი გუდაური",
            gochaBedoidze: "გოჩა ბედოიძე, ახალი გუდაური",
            gigaKavtaradze: "გიგა ქავთარაძე, ახალი გუდაური",
            koba: "კობა მიყორაშვილი, ახალი გუდაური",
            buggyRental: "ინსტრუქტორი/ბაგების გაქირავება",
            gela: "გელა წამალაძე, ახალი გუდაური",
            lukaJ: "ლუკა ჯერმიზაშვილი, ახალი გუდაური",
            nugzarBedoidze: "ნუგზარ ბედოიძე, ახალი გუდაური",
            valerian: "ვალერიან ბედოიძე, ახალი გუდაური",
            paraGlidInst: "პარაპლანის ინსტრუქტორი",
            ketevanN: "ქეთევან ნაზღაიძე, ახალი გუდაური",
            sandroJaniashvili: "სანდრო ჯანიაშვილი, ახალი გუდაური",
            nodarTsiklauri: " ნოდარ წიკლაური, ახალი გუდაური",
            passengerTrans: "მგზავრთა გადაყვანა",
            davidJaniashvili: " დავითი ჯანიაშვილი, ახალი გუდაური",
            passengerTransWithDelic: "დელიკით მგზავრთა გადაყვანა",
            dimitriSeturidze: "დიმიტრი სეთურიძე, ახალი გუდაური",
            davBedoidze: "დავითი ბედოიძე, ახალი გუდაური",
            maia: "მაია ავსაჯანიშვილი, ახალი გუდაური",
          },
          payerPersonalNumberError: "პირადი ნომერი უნდა შეიცავდეს 11 ციფრს",
          amount: "თანხა",
          amountToPay: "თანხა",
          termsAndCondition: "წესები და პირობები",
          privacyPolicy: "მონაცემთა დამუშავების პოლიტიკა",
          selectBank: "აირჩიეთ ბანკი",
          onlineBank: "ონლაინ ბანკი",
          learnMore: "გაიგე მეტი",
          tip: "თიფი",
          comment: "კომენტარი",
          installmentText: "დაყავი 4 ნაწილად",
          inputs: {
            noneResident: "არა რეზიდენტი",
            payerName: "გადამხდელის სახელი",
            additionaDescription: "დანიშნულება",
            enterNumber: "Enter your number",
            description: "დანიშნულება",
            payerPersonalNumber: "გადამადხდელის პირადი ნომერი",
            personalOrPassport: "პირადი ან პასპორტის ნომერი",
          },
          qrTerminal: {
            qrNavigation: {
              terminalNavTitle1: "უნიკალური QR",
              terminalNavDescription1:
                "ყველე მარჩანტისთვის გენერირდება უნიკალური QR კოდი",
              terminalNavTitle2: "მყისიერი ასახვა",
              terminalNavDescription2:
                "ტრანზაქციები ხორციელდება რეალურ დროში, მყისიერად",
              terminalNavTitle3: "რეგისტრაციის გარეშე",
              terminalNavDescription3:
                "გადახდა შესაძლებელია აპლიკაციის და რეგისტრაციის გარეშე",
              terminalNavTitle4: "თიფი და შეფასება",
              terminalNavDescription4:
                "ჩაშენებულია თიფების და შეფასების ფუნქციონალი",
              terminalNavTitle5: "ინტეგრირებული",
              terminalNavDescription5:
                "ინტეგრირებულია ყველა ძირითად ERP და POS სისტემასთან",
              terminalNavTitle6: "გადახდის მეთოდები",
              terminalNavDescription6:
                "ყველა პოპულარული გადახდის მეთოდი, როგორიცაა Apple Pay, Google Pay, Crypto და სხვა",
            },
            qrHowTo: {
              title: "როგორ მუშაობს?",
              placeQrTerminal: "განათავსე QR ტერმინალი",
              placeQrTerminalDesc1:
                "განათავსე QR ტერმინალი შენს სავაჭრო ობიექტში. მომხმარებელი ასკანერებს, შეჰყავს თანხა და იხდის.",
              placeQrTerminalDesc2:
                "ან <strong>გამოიყენე ინტეგრაცია ERP პროგრამებთან</strong> ",
              placeQrTerminalDesc2Span: "(Fina, Optimo, Balance, Apex)",
              placeQrTerminalDesc3:
                ", და ისე, როგორც ტრადიციული ტერმინალზე, დასკანერებისას გადასახდელი თანხა ავტომატურად აისახება სმარტფონის ეკრანზე.",
              placeQrTerminalDesc4:
                "თუ გაქვს რესტორანი, <strong>დაიტანე QR კოდი მაგიდის ჩეკზე</strong> ავტომატურად.",
              trackPayments: "თვალი ადევნე ჩარიცხვებს <br>რეალურ დროში",
              trackPaymentsDesc1:
                "ყველა ჩარიცხვა მომენტალურად აისახება როგორც აპლიკაციაში, ისე დეშბორდზე. ",
              trackPaymentsDesc2:
                "შენ ხედავ ვინ, რა დროს, და რამდენი გადაიხადა. ასევე დღის, თვის ან კონკრეტული პერიოდის ჩარიცხვების ჯამს.",
              trackPaymentsDesc3:
                "შეგიძლია დააგენერირო და ჩამოტვირთო ინვოისი თითოეული გადახდისთვის.",
              getCustomerFeedback: "მიიღე უკუკავშირი<br> მომხმარებლისგან",
              getCustomerFeedbackDesc1:
                "მომხმარებელს საშუალება აქვს შეგაფასოს და ასევე დაგიტოვოს თიფები.",
              getCustomerFeedbackDesc2:
                "შენი შეფასებების საშუალო მაჩვენებელს შენს პროფილზე ნახავ, ხოლო ჩარიცხული თიფები აისახება ტრანზაქციებში.",
              manageBranches: "მართე ფილიალები ერთი <br>დეშბორდიდან",
              manageBranchesDesc1:
                "დეშბორდზე შენ საშუალება გექნება დინამიკაში ადევნო თვალი კომპანიის ძირითად ფინანსურ მაჩვენებლებს, მიმდინარე და ისტორიულ ტრანზაქციებს, მომხმარებლის უკუკავშირს და სხვა.",
              manageBranchesDesc2:
                "რაც მთავარია, <strong>შენ შეგიძლია დაამატო, დაარედაქტირო, დამალო ან წაშალო ფილიალები</strong>.</br> შენი სურვილისამებრ, თანხა ჩაგერიცხება ცენტრალიზებულად, ან გაუწერე განსხვავებული საბანკო ანგარიში ნებისმიერ ფილიალს.",
            },
            qrUserFeedback: {
              userFeedbackAboutUs: "ჩვენი მომხმარებლები ჩვენ შესახებ",
              tamarMaisuradze: "თამარ მაისურაძე",
              vasoKhatiashvili: "ვასო ხატიაშვილი",
              operationsDirector: "ოპერაციული დირექტორი",
              ninoMurvanidze: "ნინო მურვანიძე",
              generalManager: "გენერალური მენეჯერი",
              ninoKobakhidze: "ნინო კობახიძე",
              foodStore: "მთავარი ბუღალტერი",
              vasoKhatiashviliFeedback:
                "„keepz-თან ინტერგაციით გამარტივდა tip ის დატოვების პროცესი და, შეიძლება ითქვას, წახალისდა კიდეც. მოიხსნა ის უხერხულობა, რომელიც თანხის ოდენობასთან დაკავშირებულ კომუნიკაციას ახლავს. ის თანაბრად არის მორგებული როგორც მომხმარებელზე, ასევე ჩვენზე.“.",
              ninoMurvanidzeFeedback:
                "„ჩვენი ბევრი მომხმარებელი უცხოელია და გადახდის მეთოდი რომელიც კონკრეტულ ბანკზე არაა მიბმული უფრო მოსახერხებელი აღმოჩნდა. keepz-თან თანამშრომლობა ორივე მხარისთვის შედეგიანია, დაკვირვებამ აჩვენა, რომ  მომხმარებელთა დიდი ნაწილი სწორედ keepz-ის გადახდის მეთოდს არჩევს.”",
              ninoKobakhidzeFeedback:
                "„Keepz-თან ინტეგრაციამ კრიპტოაქტივებით გადახდის პროცესი გაამარტივა. მომხმარებლების უმრავლესობისთვის სასმელების კრიპტოაქტივებით გადახდა მისასალმებელი ამბავია. Keepz-მა საშუალება მოგვცა, მოვრგებოდით მათ განსხვავებულ ინტერესებს და  ჩვენი ბიზნესი უფრო ინოვაციური გაგვეხადა.”",
            },
          },
          leaveInfo: "დატოვეთ <br> საკონტაქტო <br> ინფორმაცია",
          name: "საკონტაქტო პირის სახელი",
          companyName: "კომპანიის სახელი",
          mobileNumber: "ტელეფონის ნომერი",
          howQrTerminal: "როგორ გამოვიყენოთ QR ტერმინალი",
          requestedPayment: "გადასახდელი თანხა",
          enterAmount: "შეიყვანეთ თანხა",
          paymentMethod: "გადახდის მეთოდი",
          retry: "თავიდან ცდა",
          paymentProcessing: "გადახდა მუშავდება",
          keepz: "ქიფსი",
          why: "რატომ",
          tenTime: "10-ჯერ",
          qrCheck: {
            firstBullet: "დაბეჭდე გადახდის QR პირდაპირ  ქვითარზე",
            secondBullet:
              "მომხმარებელი იხდის მარტივად მარტივად და ტოვებს რესტორანს",
            thirdBullet:
              "QR კოდი ინტეგრირებულია თქვენს ERP/POS სისტემასთან, გადახდები აისახება ავტომატურად",
            fourthBullet:
              "იდეალურია რესტორნების, სასტუმროებისა და მსგავსი დაწესებულებებისთვის",
          },
          qrPayment: {
            firstBullet:
              "მომხმარებელი ასკანერებს თავის მაგიდაზე განთავსებულ QR კოდს",
            secondBullet: "ხედავს თავის მიმდინარე ჩეკს და გადასახდელ თანხას",
            thirdBullet:
              "აჭერს გადახდას და იხდის Keepz-ში ნებისმიერი ხელმისაწვდომი გადახდის მეთოდით",
            fourthBullet: "გააუმჯობესე მომხმარებლის გამოცდილება და დაზოგე დრო",
          },
          price: {
            pricing: "ფასი",
            fixedCommision: "ფიქსირებული <br> საკომისიო",
            fixedCommisionDesc:
              "არანაირი ფიქსირებული გადასახადი, თუ საკომისიო თვეში 5₾-ს მიაღწევს.",
            mobilebank: "მობაილ ბანკი",
            openBanking: "(ღია ბანკინგი)",
            commision: "საკომისიო",
            cryptoCurrency: "კრიპტოვალუტა",
            bankCards: "საბანკო ბარათები",
            haveQuestions: "გაქვს კითხვები? გაგვესაუბრე",
            leaveNumber: "დაგვიტოვე ნომერი და მალე დაგირეკავთ",
          },
          priceDesc: "უფრო იაფი  ვიდრე <br/> ტრადიციული  ტერმინალი",
          priceDescTwo:
            "გამოსცადე ინოვაციური გადახდის მეთოდი თვეში <br/> 10ჯერ უფრო ნაკლები თანხის სანაცვლოდ.",
          whyKeepz: {
            erp: "ERP სისტემასთან ინტეგრირებული  QR ტერმინალი - სკა",
            qrTerminal: "QR ტერმინალი უბნის მაღაზიაში",
            qrTerminalBeautySalon: "QR ტემინალი სალონში (Tenten)",
            parkingKeepz: "პარკირების გადახდა ლილო მოლში",
            cryptoPay: "კრიპტოვალუტით გადახდა",
            taxiQr: "QR გადახდა ტაქსიში",
            useagExample: "გამოყენების მაგალითები",
            versatile: "მოსახერხებელი",
            versatileDesc:
              "მხოლოდ ერთი QR კოდის გამოყენებით, მიიღეთ გადახდები შემდეგი საშუალებები:  Visa, Mastercard, Amex, Apple Pay, Google Pay, Crypto & სხვა.",
            affordable: "უფასო",
            affordableDesc:
              "არანაირი ფიქსირებული გადასახადი და 0% საკომისიო გადახდებზე, სადაც  შენ წყვეტ, ვინ გადაიხდის საკომისიოს.",
            fast: "სწრაფი",
            fastDesc:
              "თანხის მომენტალური ჩარიცხვა მერჩანტის საბანკო ანგარიშზე, ყველა ქართულ ბანკში 24/7 რეჟიმში.",
            multilevel: "ყველაფერი ერთში",
            multilevelDesc:
              "ერთი QR - მრავალი გადახდის მეთოდი: Apple Pay, Google Pay, ბანკის ბარათები, მობაილ ბანკი, კრიპტო და სხვა.",
          },

          amountOfCustomersMd: "200+ ბიზნესი უკვე იღებს გადახდებს Keepz-ით!",
          amountOfCustomers:
            "200+ ბიზნესი უკვე იღებს <br/> გადახდებს Keepz-ით!",
          buttons: {
            integration: "ინტეგრაცია",
            reachOut: "დაგვიკავშირდი",
            seeVideo: "ნახე ვიდეო",
            send: "გაგზავნა",
            price: "ფასი",
            getStarted: "გამოსცადე",
            request: "მოითხოვე",
            seeAll: "ყველას ნახვა",
            reqCall: "მოითხოვე ზარი",
          },
          navigation: {
            products: "პროდუქტები",
            price: "ფასი",
            howGetIn: "როგორ ჩავერთო",
            integration: "ინტეგრაცია",
            forBusiness: "ბიზნესისთვის",
            logIn: "შესვლა",
            check: "გამოსცადე",
          },
          header: {
            title: "ტრადიციული ტერმინალის </br> ციფრული ალტერნატივა",
            titleNext: "სრულიად უფასოდ",
            description:
              "მიიღეთ გადახდები ტერმინალის ან მომხმარებლის რეგისტრაციის გარეშე. ასახეთ შემოსავალი თქვენს საბანკო ანგარიშზე მყისიერად და ისარგებლეთ ხელმისაწვდომობით ყველგან.",
          },

          register: {
            register: "რეგისტრაცია",
            title: "მიიღეთ თქვენი <br/> პირველი გადახდა",
            download: "1. გადმოწერე",
            downloadDesc: "გადმოწერე აპლიკაცია და გაიარე რეგისტრაცია მარტივად.",
            getQR: "2. მიიღე QR",
            getQRDesc:
              "შენთვის მომენტალურად დაგენერირდება QR კოდი, რითაც შეძლებ გადახდების მიღებას.",
            start: "3. დაიწყე  ",
            startDesc: "დაიწყე გადახდების მიღება, მარტივად და სწრაფად.",
          },

          requestAndSend: {
            title: "მოითხოვე შენი QR <br/> ტერმინალი ახლავე!",
            description:
              "შეიყვანე შენი საკონტაქტო ინფორმაცია და შენს <br/> ინოვაციურ ტერმინალს სულ მალე მიიღებ შენს მისამართზე.",
            descriptionSm:
              "შეიყვანე შენი საკონტაქტო ინფორმაცია და შენს  ინოვაციურ ტერმინალს სულ მალე მიიღებ შენს მისამართზე.",
          },

          footer: {
            menu: "მენიუ",
            location:
              "13თ მიხეილ თამარაშვილი ქ. <br/> თბილისი 0179 (Hive Business Centre)",
            whyKeepz: "რატომ Keepz-ი",
            developers: "დეველოპერებისთვის",
            company: "კომპანია",
            downloadApp: "გადმოწერე აპპი",
          },

          statistic: {
            title: "ყველაზე სწრაფად მზარდი <br/> გადახდის ტექნოლოგია",
            successfulTransfer: "წარმატებული გადარიცხვა  ყოველთვიურად",
            successfulTransferMob: "წარმატებული გადარიცხვა  ყოველთვიურად",
            successfulTransferMob: "წარმატებული გადარიცხვა ყოველთვიურად",
            onBoardedMerchants: "რეგისტრირებული ბიზნესი",
            serviceUptime: "სერვისის მუშაობის მაჩვენებელი",
            satisfactionRate: "კმაყოფილების ხარისხი",
          },

          forBusiness: {
            keepzForbusiness: "KEEPZ ბიზნესისთვის",
            getProfit: "მიიღე სარგებელი ჩვენთან პარტნიორობით",
            qrBank: "QR მობაილ <br/> ბანკისთვის",
            qrBankTwo: "გადაიხადე ყველგან, სადაც არის ქიფზის QR",
            qrBankDe:
              "ჩააშენე ქიფზის QR წამკითხველი შენს ფინანსურ აპლიკაციაში. მიეცი შესაძლებლობა შენს მომხმარებელს გადაიხადოს შენი აპლიკაციით ყველგან, სადაც ქიფზია. სანაცვლოდ მიიღე სარგებელი ყველა გადახდიდან.",
            qrBankForWho: "ვისთვის არის?",
            firstBullet: "მობაილ ბანკებისთვის",
            secondBullet: "ციფრული საფულეებისთვის",
            thirdBullet: "ლოიალობის აპლიკაციებისთვის",
            fourthBullet: "სხვა ციფრული გადახდების აპლიკაციებისთვის",
            qrAgent: "QR <br/> აგენტი",
            qrAgentTwo: "განათავსე ქიფზი შენი კლიენტის ობიექტებზე",
            qrAgentDesc:
              "განათავსე ქიფზის QR ტერმინალი შენი მერჩანტების ობიექტებში, სანაცვლოდ ყველა გადახდიდან მიიღე",
            qrAgentSpan: "სარგებელი გადახდილი თანხის 0.25%.",
          },

          paymentTypes: {
            selectPayment: "აირჩიე გადახდის მეთოდი",
            forMerchants: "მერჩანტებისთვის",
            paymentsForAnyOcasion: "გადახდები ყველა სიტუაციისთვის",
            paymentsForAnyOcasionSm: "გადახდები ყველა <br/> სიტუაციისთვის",
            forFinancialInstitutions: "ფინანსური ორგანიზაციებისთვის",
            becomeOurPartner: "მიიღე სარგებელი <br/> keepz-თან პარტნორობით",
            terminalForMerchant: {
              title: "QR  ტერმინალი",
              titleB: "QR <br/> ტერმინალი",
              headLines: "გაიმარტივე გადახდების\nმიღება",
              description:
                "გაქვს მაღაზია, სალონი, ხარ გარე-მოვაჭრე დაკავებული ხარ პროდუქტისა თუ მომხმარებლის რეალიზაციით? -  მიიღე უკონტაქტო გადახდები სწრაფად და მარტივად. გამოსცადე ინოვაციური QR ტერმინალის შესაძლებლობები.",
            },
            qrForMobileBanks: {
              title: "QR მობაილ ბანკებისთვის",
              headLines: "გადახდა პირდაპირ მობაილ ბანკის აპიდან",
            },

            individualPayment: {
              title: "ინდივიდუალური ანგარიშსწორება",
              titleB: "ინდივიდუალური <br/> ანგარიშსწორება",
              headLines: "მიიღე ანაზღაურება სწრაფად და მარტივად",
              description:
                "Keepz-ის ინდივიდუალური ანგარიშსწორება გაძლევს საშუალებას სწრაფად და მარტივად მიიღო ანაზღაურება შენი შესრულებული სამუშაოსთვის. არ აქვს მნიშვნელობა ხარ ხელოსანი, ტაქსის მძღოლი, დამლაგებელი თუ ნებისმიერი სერვისის მიმწოდებელი - Keepz-ი გაგიმარტივებს თანხის მიღებას.",
            },
            distancePayment: {
              title: "დისტანციური გადახდა",
              titleB: "დისტანციური <br/> გადახდა",
              headLines: "მიიღე გადახდა  ნებისმიერი ადგილიდან",
              description:
                "დისტანციური გადახდის თავისუფლება და მოქნილობა ქიფსთან ერთად. გაუგზავნე გადახდის ბმული მომხმარებელს და მიიღე გადახდები დისტანციურად. Პროცესი უმარტივესია - წარმატებული გადახდის შესახებ შეტყობინებას მომენტალურად მიიღებ.",
            },

            QRcheck: {
              title: "QR <br/> ჩეკი",
              titleB: "QR  ჩეკი",
              headLines: "გადახდის QR პირდაპირ ჩეკზე",
              description:
                "გაიმარტივე თიფის მიღება Keepz-ის დახმარებით. არ აქვს მნიშვნელობა რომელ სფეროში ხარ - ხარ მიმტანი, ბარმენი, კურიერი, საწვავის ჩამსხმელი თუ მძღოლი - გაზარდე შენი შემოსავალი უწყვეტი გადახდის გამოცდილებით, მიიღე თიფი ციფრულად, ნაღდი ანგარიშსწორების გარეშე პირდაპირ შენს საბანკო ანგარიშზე.",
            },
            qrForRestaurant: {
              title: "QR გადახდა  რესტორნისთვის",
              titleB: "QR გადახდა <br/> რესტორნისთვის",
              headLines: "მენიუ და გადახდა ერთ სივრცეში",
              description:
                "Keepz-ი უკვე ინტეგრირებულია შენი ბიზნესის მართვის სისტემაში. დაგვიკავშირდი და გაიაქტიურე ეფექტური გადახდები. მიიღე გადახდები მაღაზიის POS სისტემაშივე.",
            },
            onlinePayments: {
              title: "ონლაინ გადახდები",
              titleB: "ონლაინ <br/> გადახდები",
              headLines: "ერთი ინტეგრაცია, მრავალი გადახდის მეთოდი",
              description:
                "ულიმიტო შესაძლებლობა ციფრული გადახდების მისაღებად.მიიღე გამარტივებული გადახდები შენი ონლაინ მაღაზიისთვის. ერთი მარტივი ინტეგრაციით მომხმარებელს საშუალებას აძლევ გადაიხადოს როგორც ბანკის ბარათით, ასევე Apple Pay, Google Pay-სა და კრიპტოვალუტის მეშვეობით.",
            },
            pay2keepz: {
              title: "⁠PAY-2-KEEPZ",
              headLines: "გადაიხადე ყველგან, სადაც არის Keepz-ის QR",
              description:
                "ჩააშენე Keepz-ის SDK შენს ფინანსურ აპლიკაციაში. მიეცი შესაძლებლობა შენს მომხმარებელს გადაიხადოს შენი აპლიკაციით ყველგან, სადაც Keepz-ია. სანაცვლოდ მიიღე სარგებელი ყველა გადახდიდან.",
            },
            keepzSubMerchant: {
              title: "KEEPZ SUB-MERCHANT",
              title: "KEEPZ <br/> SUB-MERCHANT",
              headLines: "განათავსე Keepz-ი შენი კლიენტის ობიექტებზე",
              description:
                "დაამატე Keepz შენს მერჩანტებთან და მიიღე სარგებელი თითოეული ტრანზაქციიდან.",
            },
          },
          description: {
            ibanIsBlocked:
              "მიმღების ანგარიში დაბლოკილია. გთხოვთ, დაუკავშირდეთ Keepz-ის მხარდაჭერის ცენტრს.",
            washerError: "მოწყობილობა დროებით მიუწვდომელია",
            issuerError:
              "ოპერაცია უარყო თქვენმა ბანკმა. დაუკავშირდით თქვენს ბანკს",
            generalError: "ბარათის შეცდომა. სცადეთ თავიდან",
            cardNotSupported:
              "ბარათის ტიპი არ არის მხარდაჭერილი. სცადეთ სხვა ბარათი.",
            cardExpired: "ბარათის ვადა ამოიწურა. გამოიყენეთ აქტიური ბარათი.",
            cardBlocked: "ბარათი დაბლოკილია. დაუკავშირდით თქვენს ბანკს",
            cardNotFound: "ბარათი ვერ მოიძებნა. გადაამოწმეთ დეტალები",
            invalidCardData: "ბარათის დეტალები არასწორია",
            notEnoughFunds: "ანგარიშზე არასაკმარისი თანხაა",
            paymentCancelled: "გადახდა გაუქმდა",
            paymentCancelledDesc:
              "გთხოვთ, მიმართეთ ოპერატორს ახლის შესაქმნელად",
            payProgress: "გადახდა პროცესშია გთხოვთ სცადეთ მოგვიანებით",
            orderInProgress: "გადახდის დავალება პროგრესშია",
            limitsProblem: "ლიმიტის პრობლემა, სცადეთ ხელახლა",
            informationNotFound: "ინფორმაცია ვერ მოიძებნა, სცადეთ ხელახლა",
            resourcesNotFound: "მონაცემები ვერ მოიძებნა, სცადეთ ხელახლა",
            formatError: "ფორმატის შეცდომა",
            provideError: "პროვაიდერის შეცდომა, სცადეთ ხელახლა",
            mistake: "დაფიქსირდა შეცდომა, სცადეთ ხელახლა",
            informationIsNotValid: `ინფორმაცია არასაკმარისია <br> ან არავალიდურია`,
            recipientBlocked: "მიმღები დაბლოკილია",
            beneficarylimit: "მიმღებმა გადააჭარბა\nთანხის მიღების ლიმიტს",
            productExpired: "ლინკი აღარ არის აქტიური",
            orderExpired: "საგადახდო დავალება\n ვადაგასულია",
            noValid: "საგადახდო დავალება <br/> აღარ არის ვალიდური",
            paymentProcessing: "ტრანზაქცია მუშავდება და\nმალე დადასტურდება.",
            continueWithApp: "გააგრძელე აპლიკაციაში",
            continueAsGuest: "გააგრძელე ვებში",
            pay: "გადახდა",
            sendTips: "გააგზავნი თიფი რამდენიმე კლიკით",
            customAmount: "სხვა ოდენობა",
            OpenLinkFromMobile: "გთხოვთ გახსნათ ლინკი ტელეფონით",
            transactionComplete: "გადარიცხვა შესრულებულია",
            yourTipWasSentToServer: "თქვენი თიფი გაგზავნილია მიმღებთან",
            paymentSuccessful: "გადარიცხვა წარმატებით განხორციელდა!",
            paymentSuccessfulInfo:
              "{{amount}} {{currency}} გაგზავნილია მიმღებთან",
            paymentIsPending: "{{amount}} {{currency}} იგზავნება მიმღებთან",
            transactionFailed: "გადარიცხვა უარყოფილია",
            gateError: "შლაგბაუმი დროებით მიუწვდომელია",
            limitError: "თანხა არ ზის ლიმიტში.",
            qrCheckError: "გადახდის დავალება არ მოიძებნა,\nსცადეთ თავიდან.",
            orderPaid: "შეკვეთა გადახდილია",
            paymentProgress: "გადახდა პროცესშია",
            tryAgain: "გთხოვთ სცადეთ მოგვიანებით",
            paymentProgressTwo: "გადახდა პროცესშია",
            pleaseCheckFunds: "გადახდა არ განხორციელდა. სცადეთ თავიდან.",
            pleaseCheckFundsV2:
              "დარწმუნდით, რომ სწორად შეიყვანეთ ბარათის მონაცემები და გაქვთ საკმარისი თანხა ანგარიშზე",
            enterAmount:
              "შეიყვანეთ თანხა {{ minValDB }}{{currency}}-დან  {{ maxValDB }}{{currency}}-მდე",
            totalPayable: "საკომისიოს ჩათვლით {{ total }} {{currency}}",
            transactionProcessing: "ტრანზაქცია მუშავდება",
            youWillGetSMS:
              "თანხის გადარიცხვის თაობაზე მიიღებთ SMS შეტყობინებას",
            agreetc: `გადახდაზე დაჭერით თქვენ  ეთანხმებით`,
            termsAndCon: "ჩვენს მომსახურების პირობებს",
            limitHeader: `ჩარიცხვის ლიმიტი ამოწურულია`,
            limitBody: `მიმღებს ამოწურული აქვს გადახდების მიღების ლიმიტი მიმდინარე თვეში.`,
            limitBodyTwo: `მიმღებს ამოწურული აქვს გადახდების მიღების დღიური ლიმიტი.`,
            payzeContinueWith: `Apple Pay`,
            payzePayWithCard: `საბანკო ბარათი`,
            somethingWentWrong: `დაფიქსირდა შეცდომა`,
            weReWorkingOnGettingThisFixed: `ვმუშაობთ პრობლემის მოგვარებაზე`,
            done: "დასრულება",
            yourTipWasSuccessfullySentToYourServersAccount: `თქვენი თიფი წარმატებით გაიგზავნა`,
            serviceAccount: "სერვერის ანგარიშზე",
            skip: "გამოტოვება",
          },
          ratings: {
            Terrible: "ძალიან ცუდი",
            Bad: "ცუდი",
            Normal: "საშუალო",
            Good: "კარგი",
            Great: "ძალიან კარგი",
            pleaseRate: "გთხოვ შეაფასე",
            thanksForFeedback: `მადლობა\nუკუკავშირისთვის!`,
          },
          privacy: {
            title: "პერსონალური მონაცემების დამუშავების პოლიტიკა",
            intro: "შესავალი",
            intro1: `პერსონალური მონაცემების დამუშავების პოლიტიკა (ქვემოთ - პოლიტიკა) განსაზღვრავს იმ გზებს,
              რითაც ჩვენ ვაგროვებთ, ვიყენებთ, ვაცხადებთ და ვიცავთ პირად მონაცემებს საგადახდო
              ტრანზაქციების განხორციელებასთან დაკავშირებულ და ანგარიშის რეგისტრაციის მახასიათებლებისთვის
              ჩვენს მობილურ აპლიკაციაში – Keepz („მომსახურება“). ჩვენ სერიოზულად ვუდგებით პერსონალური
              მონაცემების დაცვის საკითხს და ვესწრაფით დავიცვათ მონაცემების დაცვასთან დაკავშირებული
              კანონების მოთხოვნები. წინამდებარე პერსონალური მონაცემების დამუშავების პოლიტიკა რეგულირდება
              საქართველოს კანონით პერსონალური მონაცემების დამუშავების შესახებ. ამ კანონის მიზნებიდან
              გამომდინარე, შპს "ბიქეიენ301 ჯორჯია"-მ უნდა იმოქმედოს როგორც მონაცემების დამამუშავებელმა, ხოლო კლიენტი
              უნდა იყოს მონაცემების სუბიექტი.`,
            intro2: `Keepz-ის და/ან შპს "ბიქეიენ301 ჯორჯია"-ს მომსახურების წვდომით და/ან გამოყენებით, თქვენ ეთანხმებით და
              ადასტურებთ, რომ გაეცანით გაიგეთ, და ეთანხმებით პერსონალური მონაცემების დამუშავების მოცემულ
              პოლიტიკას. ასევე, მას შემდეგ რაც, დარეგისტრირდებით Keepz App-ში და დაიწყებთ ჩვენი
              მომსახურებით სარგებლობას, ეს პოლიტიკა გახდება პირობებისა და დებულებების დოკუმენტის
              დამატებითი ნაწილი.`,
            intro3: `შპს "ბიქეიენ301 ჯორჯია" უფლებამოსილია, მოახდინოს ამ პოლიტიკის მოდიფიცირება საკუთარი შეხედულების
              საფუძველზე, ნებისმიერ დროს, ვებ-გვერდზე მისი გამოქვეყნებითა თუ კლიენტისათვის მისი
              სხვაგვარად ხელმისაწვდომობის უზრუნველყოფით. ამ პოლიტიკის განახლებული ვერსია ძალაში შედის
              მისი გამოქვეყნებისთანავე.`,
            goals: "მიზნები",
            goals1: `ძირითადი მიზანი, რატომაც შპს "ბიქეიენ301 ჯორჯია" აგროვებს თქვენს პირად ინფორმაციას, არის კლიენტებისათვის
              საგადახდო მომსახურების მიწოდება. არის რა საგადახდო მომსახურების პროვაიდერი, რაც
              რეგულირდება საქართველოს კანონმდებლობით, შპს "ბიქეიენ301 ჯორჯია" კანონითაა ვალდებული დაადგინოს და
              შეამოწმოს კლიენტის ვინაობა მასთან საქმიან ურთიერთობაში შესვლამდე, ასევე, მომსახურების
              მიწოდების დროს მოითხოვოს დამატებითი ინფორმაცია, შეაფასოს და შეინახოს ეს ინფორმაცია კანონით
              დადგენილი ვადების შესაბამისად. აღნიშნულიდან გამომდინარე, თქვენ ვალდებული ხართ მიაწოდოთ
              სწორი და სრული ინფორმაცია.`,
            collectData: "მონაცემების შეგროვება",
            collectData1: `ჩვენ შეგვიძლია შემდეგი სახის პერსონალური მონაცემები შევაგროვოთ ჩვენი სერვისების
              მომხმარებლებისაგან:`,
            dataList1: `ინფორმაცია პიროვნების შესახებ, მათ შორის სახელი, გვარი, ფოტოსურათი, დაბადების
              თარიღი, პასპორტის ნომერი, ეროვნული საიდენტიფიკაციო მოწმობის ნომერი, მართვის
              მოწმობის ნომერი, დასაქმების ისტორია, სამუშაოს სახელწოდება.`,
            dataList2: `საკონტაქტო ინფორმაცია, მათ შორის, ელექტრონული ფოსტის მისამართი და ტელეფონის
              ნომერი.`,
            dataList3: `საგადახდო ინფორმაცია, მათ შორის საბანკო ანგარიშის ნომერი, ან ინფორმაცია სხვაგვარი
              გადახდის მეთოდის შესახებ.`,
            dataList4: `ინფორმაცია ანგარიშის შესახებ, მათ შორის მომხმარებლის სახელი და პაროლი.`,
            dataUsage: `მონაცემების გამოყენება`,
            dataUsageIntro: `ჩვენ ვიყენებთ ჩვენი სერვისების მომხმარებლებისაგან შეგროვებულ პირად მონაცემებს შემდეგი
              მიზნით:`,
            dataUsageList1: `გადახდის ტრანზაქციების განსახორციელებლად`,
            dataUsageList2: `მომხმარებლის ანგარიშების შესაქმნელად და სამართვად.`,
            dataUsageList3: `მომსახურების გასაუმჯობესებლად და მომხმარებელთათვის უკეთესი მომსახურების გასაწევად.`,
            dataUsageList4: `იურიდიული და მარეგულირებლის მოთხოვნების შესაბამისად.`,
            dataDisclosure: "მონაცემების გამჟღავნება",
            dataDisclosureIntro: `ჩვენ შეგვიძლია ჩვენი სერვისების მომხმარებლებისაგან შეგროვებული პერსონალური მონაცემები
              გავუმხილოთ მესამე მხარეებს შემდეგი მიზნით:`,
            dataDisclosureList1: `ფინანსურ ინსტიტუციებსა და საგადახდო სისტემებს გადახდების დამუშავების მიზნით`,
            dataDisclosureList2: `მომსახურების პროვაიდერებს, რომლებიც გვეხმარებიან მომსახურების განხორციელებაში`,
            dataDisclosureList3: `იურიდიული და მარეგულირებლის მოთხოვნებიდან გამომდინარე`,
            dataPrivacy: `მონაცემების უსაფრთხოება`,
            dataPrivacyIntro1: `შპს "ბიქეიენ301 ჯორჯია"-ს მიზანია უზრუნველყოს კლიენტისგან მიღებული და საჯარო მონაცემთა ფაილების ყველა
              ინფორმაციის უსაფრთხოების დაცვის უმაღლესი დონე. ამ ინფორმაციის უნებართვო წვდომისაგან,
              გამოყენებისაგან, კოპირებისაგან, შემთხვევითი თუ უკანონო წაშლისაგან, შეცვლისა თუ
              გაცხადებისაგან, ისევე როგორც ნებისმიერი სახის არაუფლებამოსილი დამუშავებისაგან დაცვის
              მიზნით, შპს "ბიქეიენ301 ჯორჯია" იყენებს სათანადო სამართლებრივი, ადმინისტრაციული, ტექნიკური და ფიზიკური
              უსაფრთხოების ზომებს.`,
            dataPrivacyIntro2: `მონაცემები უნდა დამუშავდეს საქართველოს ტერიტორიაზე. მონაცემების უსაფრთხოების მიზნით
              გამოყნებულ იქნება ამაზონის ვებ-სერვისების დისტანციური სერვერები.`,
            dataPrivacyIntro3: `სახელწოდება: Amazon Web Services EMEA SARL`,
            dataPrivacyIntro4: `მისამართი: 38 Avenue John F. Kennedy, L-1855, Luxembourg`,
            dataPrivacyIntro5: `საკონტაქტო პირის სახელი, თანამდებობა და საკონტაქტო ინფორმაცია: AWS`,
            dataPrivacyIntro6: `დამუშავების პროცესი აღწერილია ამ ბმულზე`,
            dataPrivacyIntro7: `რაც რეგულირდება მონაცემების დაცვის შესახებ შეთანხმებით (DPA), რაც შეესაბამება მონაცემების
              დაცვის შესახებ ზოგად რეგულაციების (GDPR) მოთხოვნებს და მოიცავს განახლებულ SCC-ს:`,
            dataPrivacyIntro8: `შპს "ბიქეიენ301 ჯორჯია"-მ არცერთ შემთხვევაში არ უნდა განახორციელოს მონაცემების დამუშავება საქართველოსა თუ
              ევროპული ეკონომიკური არეალის იურისდიქციის მიღმა.`,
            saveData: "მონაცემების შენახვა",
            saveDataIntro: `ჩვენ მომსახურების მომხმარებლებისგან მიღებულ პირად მონაცემებს ვინახავთ, მანამდე, ვიდრე ეს
              აუცილებელია იმ მიზნების მისაღწევად, რისთვისაც ისინი შეგროვდა, კანონის მოთხოვნათა
              შესაბამისად ან ჩვენი იურიდიული ვალდებულებებიდან გამომდინარე.`,
            organizeTech: `საორგანიზაციო და ტექნოლოგიური ზომები`,
            organizeTechIntro: `შპს "ბიქეიენ301 ჯორჯია" ახორციელებს საინფორმაციო უსაფრთხოების ზომებს, რაც საკმარისია იმ მონაცემებისათვის
              რისკის შესამცირებლად, რასაც ის ამუშავებს გადახდების განხორცილების პროცესში. ინფორმაცია,
              რომლის გაცვლა ხდება მესამე მხარეებთან, დაშიფრულია SSL-ით. დისტანციურ სერვერებზე დაცულ
              მონაცემებზე წვდომა მკაცრადაა დაცული, და მხოლოდ უფლებამოსილ თანამშრომლებს აქვთ უფლება
              საჭიროების შემთხვევაში იქონიონ წვდომა. ამ თანამშრომლებს აქვთ კონფიდენციალობის დაცვის
              ვალდებულება, რაც მათი სამუშაო კონტრაქტის შემადგენელი ნაწილია. AWS დისტანციურ სერვერებზე
              დაცული ინფორმაცია ლოგიკურადაა დაყოფილი და დაშიფრული, და არცერთ მესამე მხარეს არ შეუძლია:
              ჰქონდეს მათზე წვდომა, შეცვალოს, გაანადგუროს ან სხვაგვარად დააზიანოს ისინი. მონაცემებთან
              დაკავშირებული ყველა ქმედება რეგისტრირდება და ინახება.`,
            dataAccess: "მონაცემებზე წვდომა და ჩასწორება",
            dataAccessIntro: `მომსახურების მომხმარებლებს აქვთ უფლება ჰქონდეთ წვდომა, ჩაასწორონ, ან წაშალონ თავიანთი
              პერსონალური მონაცემები, რაც ჩვენთანაა დაცული. მათ ამის გაკეთება შუძლიათ თვიანთ ანგარიშზე
              შესვლით, ან ჩვენთან დაკავშირებით ქვემოთ მოცემული საკონტაქტო ინფორმაციის მეშვეობით, გარდა
              იმ შემთხვევისა, თუკი სხვა რამ არ არის განსაზღვრული საქართველოს კანონმდებლობით.`,
            contact: "დაგვიკავშირდით",
            contactIntro: `თუკი გაქვთ შეკითხვები ამ პოლიტიკისა თუ მონაცემების დაცვის ჩვენეულ პრაქტიკასთან
              დაკავშრებით, გთხოვთ, დაგვიკავშირდეთ ელექტრონული ფოსტით მისამართზე`,
          },
        },
      },
    },
  });

export default i18n;
