import { PRE_DISCOUNT_AMOUNT, SET_AMOUNT } from "features/bog/reducer/actions";
import {
  ceilToTwo,
  getCampaignName,
  sortUserLabelsByLabelId,
} from "utils/functions";
import formatter from "currency.js";

const { useEffect } = require("react");

const useCalculateCampaign = (user, paymentType, qrType, amount, dispatch) => {
  const campaign =
    sortUserLabelsByLabelId(user, getCampaignName(paymentType)) || [];
  useEffect(() => {
    const campaign0 = campaign[0];
    const promotionInfo = campaign0?.promotionInfo;

    // Return early if conditions not met
    if (qrType === "DEFAULT" || !amount || promotionInfo?.minAmount > amount) {
      return;
    }

    if (campaign0?.promotionType !== "DISCOUNT") {
      dispatch({ type: SET_AMOUNT, payload: amount });
      return;
    }

    console.log("shemovida hookshi kampaniis");

    let discountedAmount;
    const isPercentageCompensation =
      promotionInfo?.compensationType === "PERCENTAGE";
    dispatch({ type: PRE_DISCOUNT_AMOUNT, payload: amount });
    if (isPercentageCompensation) {
      // Calculate percentage discount
      // discountedAmount = ceilToTwo((amount * promotionInfo.amount) / 100);
      discountedAmount =
        Math.floor(((amount * promotionInfo.amount) / 100) * 100) / 100;
      console.log((amount * promotionInfo.amount) / 100, "direct amount");
      console.log(discountedAmount, "rounded up amount");

      //   const discountedTotal = formatter(amount).subtract(discountedAmount);
      const exceedsCap = discountedAmount > promotionInfo.capPerTransaction;

      if (exceedsCap) {
        discountedAmount = promotionInfo.capPerTransaction;
      }
    } else {
      // Fixed amount discount
      discountedAmount = promotionInfo?.amount;
    }

    dispatch({
      type: SET_AMOUNT,
      payload: formatter(amount).subtract(discountedAmount),
    });
  }, [paymentType, user, amount]);
};

export default useCalculateCampaign;
