import React from "react";
import { Trans, useTranslation } from "react-i18next";

const STATUSES = [
  "COMPLETED",
  "PRECONDITION_FAILED",
  "ERROR",
  "CANCELLED",
  "FAIL",
  "INTERNET",
  "FORBIDDEN",
  "METHOD_NOT_ALLOWED",
  "CONFLICT",
  "NOT_FOUND",
  424,
  409,
  425,
  429,
  "GONE",
  "UNPROCESSABLE_ENTITY",
  410,
];

const PaymentFeedback = ({ type, action, className, group }) => {
  const { t } = useTranslation();
  let msg = "";
  let title = "";
  let image = null;
  switch (type) {
    case STATUSES[0]:
      title = t("description.transactionComplete");
      msg = t("description.yourTipWasSentToServer");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Path_1451"
            data-name="Path 1451"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-2.245-3.273a1.913,1.913,0,0,1-1.493-.775l-4.12-4.964a1.633,1.633,0,0,1-.431-1.1,1.464,1.464,0,0,1,1.464-1.478,1.551,1.551,0,0,1,1.249.646l3.287,4.075,6.33-10.072a1.512,1.512,0,0,1,1.292-.832,1.464,1.464,0,0,1,1.536,1.392,2.186,2.186,0,0,1-.388,1.076L-.8-4.09A1.73,1.73,0,0,1-2.245-3.273Z"
            transform="translate(15.308 25.884)"
            fill="#9dd838"
          />
        </svg>
      );

      break;
    case 4005:
      title = t("description.limitHeader");
      msg = t("description.limitBody");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="Group_12247"
            data-name="Group 12247"
            transform="translate(-212 -332)"
          >
            <circle
              id="Ellipse_610"
              data-name="Ellipse 610"
              cx="15"
              cy="15"
              r="15"
              transform="translate(212 332)"
              fill="#d53943"
              opacity="0.2"
            />
            <g
              id="Group_12247-2"
              data-name="Group 12247"
              transform="translate(-0.5)"
            >
              <line
                id="Line_36"
                data-name="Line 36"
                y2="9"
                transform="translate(227.5 339.5)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
              <line
                id="Line_37"
                data-name="Line 37"
                transform="translate(227.5 354.095)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case STATUSES[2]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case STATUSES[4]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case STATUSES[3]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case STATUSES[5]:
      title = "Network Problem";
      msg = "Please check your internet connection!";
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case STATUSES[6]:
      title = t("Your VAT number is blocked!");
      msg = t("Contact Keepz support");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case STATUSES[7]:
      title = t("description.limitHeader");
      msg = "Beneficiary personal number reached limit of receiving payments!";
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="Group_12247"
            data-name="Group 12247"
            transform="translate(-212 -332)"
          >
            <circle
              id="Ellipse_610"
              data-name="Ellipse 610"
              cx="15"
              cy="15"
              r="15"
              transform="translate(212 332)"
              fill="#d53943"
              opacity="0.2"
            />
            <g
              id="Group_12247-2"
              data-name="Group 12247"
              transform="translate(-0.5)"
            >
              <line
                id="Line_36"
                data-name="Line 36"
                y2="9"
                transform="translate(227.5 339.5)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
              <line
                id="Line_37"
                data-name="Line 37"
                transform="translate(227.5 354.095)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case STATUSES[8]:
      title = "დაფიქსირდა კონფლიქტი";
      msg = "თქვენ სარგებლობთ გამოყენებული ორდერით";
      image = image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case STATUSES[9]:
      title = "Order not found";
      msg = t("description.qrCheckError");
      image = image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2109:
      title = t("description.transactionFailed");
      msg = t("description.gateError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2309:
      title = t("description.washerError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 1:
      title = "Installment already used";
      // msg = t("description.gateError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 2023:
    case 2029:
      title = t("description.recipientBlocked");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 4002:
      title = t("description.transactionFailed");
      msg = t("description.beneficarylimit");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 100:
      title = t("description.paymentCancelled");
      msg = t("description.paymentCancelledDesc");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2100:
      title = t("description.paymentProgress");
      msg = t("description.tryAgain");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 6002:
      msg = t("description.qrCheckError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2101:
      msg = t("description.orderInProgress");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 1055:
      msg = "Voucher already chosen!";
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 4017:
      msg = t("description.ibanIsBlocked");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2103:
    case 2108:
      title = t("description.orderPaid");
      // msg = t("descript");
      image = (
        <svg
          width="60"
          height="61"
          viewBox="0 0 60 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="30"
            cy="30.6113"
            r="30"
            fill="#8CC63F"
            fill-opacity="0.1"
          />
          <path
            d="M16.5 30.7449L23.4338 39.2602C24.3342 40.3659 26.0229 40.3659 26.9233 39.2602L43.5 18.9028"
            stroke="#8CC63F"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );

      break;

    case 4006:
      title = t("description.transactionFailed");
      msg = t("description.limitBodyTwo");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 2102:
    case 6054:
    case 2178:
      msg = <Trans i18nKey={"description.noValid"} />;
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 6005:
      msg = <Trans i18nKey={"description.noValid"} />;
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 6017:
    case 2217:
      title = t("description.orderExpired");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2274:
      msg = t("description.payProgress");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 2216:
    case 2301:
      title = t("description.notEnoughFunds");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 2290:
    case 2300:
      msg = t("description.invalidCardData");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case 2291:
      msg = t("description.cardNotFound");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2292:
      msg = t("description.cardBlocked");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2293:
    case 2302:
      msg = t("description.cardExpired");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2294:
      msg = t("description.cardNotSupported");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2295:
      msg = t("description.cardBlocked");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2297:
      msg = "Authentication failed by client device";
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2298:
    case 2303:
      msg = t("description.generalError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2299:
      msg = t("description.issuerError");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case 2104:
    case 2105:
    case 2106:
      title = t("description.productExpired");
      image = (
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 12848">
            <circle
              id="Ellipse 611"
              opacity="0.2"
              cx="15.5"
              cy="15.3894"
              r="15"
              fill="#D53943"
            />
            <g id="Group 12847">
              <path
                id="Line 36 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                fill="#D53943"
              />
              <path
                id="Line 37 (Stroke)"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                fill="#D53943"
              />
            </g>
          </g>
        </svg>
      );

      break;

    default:
      if (group == 1) {
        msg = <Trans i18nKey={"description.informationIsNotValid"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 2) {
        msg = <Trans i18nKey={"description.mistake"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 3) {
        title = "Group 3";
        msg = "AUTH group";
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 4) {
        msg = <Trans i18nKey={"description.provideError"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 5) {
        msg = <Trans i18nKey={"description.resourcesNotFound"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 6) {
        msg = t("description.formatError");
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 7) {
        msg = <Trans i18nKey={"description.informationNotFound"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else if (group == 8) {
        msg = <Trans i18nKey={"description.limitsProblem"} />;
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      } else {
        title = t("description.transactionFailed");
        msg = t("description.pleaseCheckFunds");
        image = (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 12848">
              <circle
                id="Ellipse 611"
                opacity="0.2"
                cx="15.5"
                cy="15.3894"
                r="15"
                fill="#D53943"
              />
              <g id="Group 12847">
                <path
                  id="Line 36 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 6.3894C16.3577 6.3894 17.0293 7.06098 17.0293 7.8894V16.6167C17.0293 17.4451 16.3577 18.1167 15.5293 18.1167C14.7009 18.1167 14.0293 17.4451 14.0293 16.6167V7.8894C14.0293 7.06098 14.7009 6.3894 15.5293 6.3894Z"
                  fill="#D53943"
                />
                <path
                  id="Line 37 (Stroke)"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5293 21.4197C16.3577 21.4197 17.0293 22.0913 17.0293 22.9197L17.0293 22.8894C17.0293 23.7178 16.3577 24.3894 15.5293 24.3894C14.7009 24.3894 14.0293 23.7178 14.0293 22.8894L14.0293 22.9197C14.0293 22.0913 14.7009 21.4197 15.5293 21.4197Z"
                  fill="#D53943"
                />
              </g>
            </g>
          </svg>
        );
      }
      break;
  }

  const relodePage = () => {
    window.location.reload();
  };
  return (
    <div
      className={`flex flex-col z-50 items-center  w-[250px]  justify-center text-center pt-[0.8rem] pb-[0.3rem] ${
        className ? "bg-white rounded-[16px] h-[200px] p-[40px] mt-[200px]" : ""
      }`}
    >
      {image}
      <h2 className="mt-[0.875rem] text-[1.0625rem] leading-[1.375rem] text-black font-bold">
        {title}
      </h2>
      <p className="text-[0.8125rem] mt-1">{msg}</p>
      <button
        className="text-black-500 uppercase font-bold mt-2"
        onClick={type == 100 ? relodePage : action}
      >
        OK
      </button>
    </div>
  );
};

export default PaymentFeedback;
